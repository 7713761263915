import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  menu = "menu",
}

interface Props {
  SetCookie: (name: string, data: any) => any;
  authenticated: boolean;
  activeHelp: boolean;
  
  updateUser: () => any;
  user: any;
  aside: boolean;
  updateAside: (flag: boolean) => any;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}
interface State {
  notices: any;
  helps: any;
  widhdraws: any;
  selectBoard: string;
  popupStatuses: string;
  aside: boolean;
  note: any;
}

export class Aside extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      helps: [],
      widhdraws: [],
      selectBoard: "1",
      popupStatuses: popupView.none,
      aside: false,
      note: [],
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일 22시28분";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr },
      { id: "jvd****", price: "83,000,000 원", date: yesterdayStr },
      { id: "vhr**", price: "82,000,000 원", date: yesterdayStr },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr },
      { id: "df****", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  RenderNotice = () => {
    return (
      <>
        {this.state.notices.map((info: any, index: any) => (
          <li
            className="realtime-event__list__item ng-scope"
            style={{ marginTop: "0px" }}
            onClick={() => {
              this.setState({ popupStatuses: popupView.notice }); //
            }}
          >
            <div className="realtime-event__list__item__subject ng-binding">
              * {info.title}
            </div>
            <div className="realtime-event__list__item__date ng-binding">
              {ConvertDate(info.regdate)}
            </div>
          </li>
        ))}
      </>
    );
  };

  RenderHelp = () => {
    return (
      <>
        {this.state.helps.map((info: any, index: any) => (
          <li
            className="realtime-event__list__item ng-scope"
            style={{ marginTop: "0px" }}
            onClick={() => {
              this.setState({ popupStatuses: popupView.help }); //
            }}
          >
            <div className="realtime-event__list__item__subject ng-binding">
              • {info.title}
            </div>
            <div className="realtime-event__list__item__date ng-binding">
              {ConvertDate(info.regdate)}
            </div>
          </li>
        ))}
      </>
    );
  };

  RenderWidhdraws = () => {
    return (
      <>
        {this.state.widhdraws.map((info: any, index: any) => (
          <li className="tr">
            <div className="user">
              <span className="bullet"></span>
              <span>{info.id}</span>
            </div>
            <div className="amount">
              <span>{info.price}</span>
            </div>
            <div className="date">
              <span>{info.date}</span>
            </div>
          </li>
        ))}
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="aside2_box1_wrap">
              <div className="aside2_box1">
                <table
                  cellSpacing={0}
                  cellPadding={0}
                  style={{
                    width: "100%",
                    border: "0",
                    borderTop: "1px solid rgba(0, 0, 0, 0.99)",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "rgb(237, 221, 216)",
                        }}
                      >
                        로그인 후 이용해주세요
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="aside2_box1_wrap">
            <div className="aside2_box1">
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{
                  borderTop: "1px solid rgba(0, 0, 0, 0.99)",
                  width: "100%",
                  border: "0",
                }}
              >
                <tbody>
                  <tr>
                    <td align="center" colSpan={2}>
                      <img src="images/70AQ7_1.jpg" width="20" />
                      <span className="font01">{this.props.user.id}</span>님
                      환영합니다.
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.tryLoginOut();
                        }}
                      >
                        <span className="btn1_2">로그아웃</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    };

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }

      return <div></div>;
    };

    return (
      <>
        <div className={this.props.aside ? "aside2 in" : "aside2"}>
          <div className="aside_wrap">
            <div className="aside_top_wrap">
              <div className="aside_top_left">
                <a href="javascript:;">
                  <img src="images/m_logo.png" width="160" />
                </a>
              </div>
              <div
                data-dismiss="aside"
                className="aside_top_right"
                onClick={() => {
                  this.props.updateAside(false);
                }}
              >
                <img src="images/m_close.png" width="40" />
              </div>
            </div>
            {RenderLogin()}

            <div className="aside2_box2_wrap">
              <div className="aside2_box2">
                <div className="con_box00">
                  <table
                    style={{ width: "100%", border: "0", textAlign: "center" }}
                    cellSpacing={2}
                    cellPadding={0}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.deposit,
                              }); //입금신청
                            }}
                          >
                            <span className="aside_btn1">입금신청</span>
                          </a>
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.withdraw,
                              }); //
                            }}
                          >
                            <span className="aside_btn1">출금신청</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        {/* <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.point,
                              }); //
                            }}
                          >
                            <span className="aside_btn1">금고</span>
                          </a>
                        </td> */}
                         <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.comp,
                              }); //
                            }}
                          >
                            <span className="aside_btn1">콤프</span>
                          </a>
                        </td>
                             <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.note,
                              }); //
                            }}
                          >
                            <span className={ "aside_btn1"}>쪽지</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.notice,
                              }); //
                            }}
                          >
                            <span className="aside_btn1">공지사항</span>
                          </a>
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.user,
                              }); //
                            }}
                          >
                            <span className={"aside_btn1"}>마이페이지</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          <a
                            onClick={() => {
                              this.setState({
                                popupStatuses: popupView.help,
                              }); //
                            }}
                          >
                            <span className={this.props.activeHelp ? "aside_btn1 neon" : "aside_btn1 "}>고객센터</span>
                          </a>
                        </td>
                   
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="customer_wrap">
              <div className="customer">
                <div className="customer_tit">고객센터 </div>
                <ul>
                  {/* <li>
                    <img src="images/sns_phone.png" />
                    &nbsp;&nbsp;&nbsp;070-0000-0000
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {RenderPopup()}
      </>
    );
  }
}
