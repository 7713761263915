import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";

import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
  menu = "menu",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="close_box" style={{ textAlign: "center" }}>
              <a
                href="javascript:;"
                className="sub_pop1_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                <img src="images/popup_close.png" />
              </a>
            </div>
            <div
              id="contents_wrap"
              style={{ background: "url(/images/bg.jpg) center top no-repeat" }}
            >
              <div className="title_wrap">
                <div className="title">마이페이지</div>
              </div>
              <div className="con_box10">
                <div className="money">
                  <ul>
                    <li>
                      <img
                        src="images/ww_icon.png"
                        style={{ height: "20px" }}
                      />{" "}
                      보유머니 :{" "}
                      <span className="font05" id="myWallet2">
                        {ConverMoeny(this.props.user.balance + this.props.user.in_balance )}
                      </span>
                      &nbsp;&nbsp;
                    </li>
                    <li>
                      <img
                        src="images/ww_icon2.png"
                        style={{ height: "20px" }}
                      />{" "}
                      콤프 :{" "}
                      <span className="font05" id="myCas2">
                        {ConverMoeny(this.props.user.rolling)}
                      </span>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="con_box10">
                <table
                  cellSpacing={0}
                  cellPadding={0}
                  className="write_title_top"
                  style={{ width: "100%", border: "0" }}
                >
                  <tbody>
                    <tr>
                      <td className="write_title">아이디</td>

                      <td className="write_basic">
                        <input
                          className="input1"
                          size={30}
                          value={this.props.user.id}
                          readOnly
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="write_title">예금주명</td>

                      <td className="write_basic">
                        <input
                          className="input1 userName"
                          size={30}
                          readOnly
                          value={this.props.user.bankowner}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">회원은행</td>

                      <td className="write_basic">
                        <input
                          className="input1 userBankName"
                          size={30}
                          readOnly
                          value={this.props.user.bankname}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">출금계좌번호</td>

                      <td className="write_basic">
                        <input
                          className="input1 userBankName"
                          size={30}
                          readOnly
                          value={this.props.user.banknum}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="write_title">새 비밀번호</td>

                      <td className="write_basic">
                        <input
                          className="input1 userBankName"
                          size={30}
                          placeholder="새 비밀번호"
                          value={this.state.pass1}
                          onChange={(e: any) => {
                            this.setState({ pass1: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">비밀번호 확인</td>

                      <td className="write_basic">
                        <input
                          className="input1 userBankName"
                          size={30}
                          placeholder="비밀번호 확인"
                          value={this.state.pass2}
                          onChange={(e: any) => {
                            this.setState({ pass2: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="con_box10">
                <div className="btn_wrap_center">
                  <ul>
                    <li style={{ width: "30%" }}>
                      <a
                        href="javascript:;"
                        style={{ width: "30%" }}
                        onClick={this.handleExchangeToPassword}
                      >
                        <span className="btn3_1" style={{ width: "30%" }}>
                          비밀번호변경
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="footer_wrap"
              style={{ background: "rgb(0, 60, 123)" }}
            >
              <img src="images/footer.png" style={{ width: "320px" }} />
            </div>
          </>
        )}
      </Popup>
    );
  }
}
