import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import Marquee from "react-fast-marquee";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  gameView: string;
  updateUser: () => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  gameEName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
  yesterdayStr: string;

  favSlot1: boolean;
  favSlot2: boolean;
  favSlot3: boolean;
  favSlot4: boolean;
  favSlot5: boolean;
  favSlot6: boolean;

  activeMode :string; 
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      gameEName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
      yesterdayStr: "",

      favSlot1: false,
      favSlot2: false,
      favSlot3: false,
      favSlot4: false,
      favSlot5: false,
      favSlot6: false,
      activeMode : 'slot',
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        console.log(s.slot);
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        // for (const casiono of Object.values(s.casiono) as any) {
        //   if (casiono.nameEn !== "Evolution") {
        //     item.push(casiono);
        //   }
        // }

        this.setState({
          casionos: s.casiono,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    this.setState({ yesterdayStr: yesterdayStr });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <div
            className="box_wrap02"
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.setState({ gameEName: info.nameEn });
                this.setState({ mode: Mode.slot });
                // this.handleSlotGames(info.code);
                this.setState({ popupStatuses: popupView.game });
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="box_box02">
              <div className="box02">
                <img src={info.mobileImg} width="120" />
              </div>
              <div className="box022">{info.nameKo}</div>
            </div>
          </div>
        ))}
      </>
    );
  };

  RenderSlotList2 = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className={
              this.state.gameCode === info.code
                ? "menu-link active"
                : "menu-link"
            }
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.handleSlotGames(info.code);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="img-cont">{info.nameEn}</div>
            <div className="text-cont">
              <span>{info.nameKo}</span>
            </div>
          </a>
        ))}
      </>
    );
  };

  RenderSlotGameList = () => {
    return (
      <>
        {this.state.slotgames.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className="game-btn"
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        // this.setState({ popupStatuses: popupView.none });
                      },
                    },
                  ],
                });
                return;
              }
          
              if (info.used !== "y") {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
                return;
              }

              this.handleOpenSlot(info.code, info.gameCompany);
            }}
          >
            <div className="main-cont">
              <img className="main-img" src={`${info.imgUrl}`} />
              <button className="play-btn">
                <i className="fa fa-play" aria-hidden="true"></i>
              </button>
            </div>
            <div className="footer">
              <span>{info.nameKo}</span>
            </div>
          </a>
        ))}
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
        <ul className="game">
          {this.state.casionos.map((info: any, index: any) => (
            <a
              href="javascript:;"
              className="cg-btn"
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            popupStatuses: popupView.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="cg-inner">
                <div className="img-cont">
                  <img src={info.mobileImg} />
                </div>
                <div className="icon-cont">{info.nameEn}</div>
                <p className="name">{info.nameKo}</p>
              </div>
            </a>
          ))}
        </ul>
      </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== Mode.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices: any = [];
    let widhdraws = this.state.widhdraws;

    this.state.notices.map((info: any, index: any) => {
      if (index < 4) {
        notices.push(info);
      }
    });

    return (
      <>
        <div className="transaction-table">
          <div className="table-heading">
            <span className="col-xs-8">공지사항/이벤트게시판/ 주간출금</span>
            <span
              className="col-xs-4 pull-right text-right"
              onClick={() => {
                this.setState({ popupStatuses: popupView.notice }); //공지사항
              }}
            >
              전체 보기
            </span>
          </div>
          <ul
            className=" list-unstyled transaction-list"
            style={{
              width: "328px",
              height: "159.5px",
              overflow: "hidden",
            }}
            id="notice-ticker"
          >
            {notices.map((info: any, index: any) => (
              <li
                role="button"
                className="ng-scope"
                onClick={() => {
                  this.setState({ popupStatuses: popupView.notice }); //공지사항
                }}
              >
                <div className="col-xs-4 text-left">
                  <span className="ng-binding">
                    {ConvertDate(info.regdate)}
                  </span>
                </div>
                <div className="col-xs-8 text-left text-white">
                  <span className="ng-binding">{info.title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  RenderRealtimeDeposit = () => {
    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));

    var year = yesterday.getFullYear();
    var month = yesterday.getMonth() + 1;
    var day = yesterday.getDate();

    var monthStr = "";
    if (Number(month) < 10) {
      monthStr = "0" + month;
    }

    var dayStr = "";
    if (Number(day) < 10) {
      dayStr = "0" + day;
    }

    var date = year + "-" + monthStr + "-" + dayStr;

    return (
      <>
        <li className="ng-scope odd" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 23:17</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">le****rh</span>
          </div>
        </li>
        <li className="ng-scope odd" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 23:34</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">oj****dfg</span>
          </div>
        </li>
        <li className="ng-scope odd" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 23:36</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">ug****0127</span>
          </div>
        </li>
        <li className="ng-scope odd" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 23:45</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">xt****oj</span>
          </div>
        </li>
        <li className="ng-scope odd" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 23:49</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">ze****34</span>
          </div>
        </li>
      </>
    );
  };

  RenderRealtimeWithdraw = () => {
    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));

    var year = yesterday.getFullYear();
    var month = yesterday.getMonth() + 1;
    var day = yesterday.getDate();

    var monthStr = "";
    if (Number(month) < 10) {
      monthStr = "0" + month;
    }

    var dayStr = "";
    if (Number(day) < 10) {
      dayStr = "0" + day;
    }

    var date = year + "-" + monthStr + "-" + dayStr;
    return (
      <>
        <li className="ng-scope" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 22:25</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">oj****3526</span>
          </div>
        </li>
        <li className="ng-scope" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 22:29</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">yg****okfe</span>
          </div>
        </li>
        <li className="ng-scope" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 22:38</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">uh****set</span>
          </div>
        </li>
        <li className="ng-scope" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 22:41</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">uh****3479</span>
          </div>
        </li>
        <li className="ng-scope" style={{ marginTop: "0px" }}>
          <div className="col-xs-5 text-left">
            <span className="ng-binding">{date} 22:57</span>
          </div>
          <div className="col-xs-3 text-right goldTxt">
            <span className="ng-binding">
              {Math.floor(Math.random() * (99 - 15) + 15)}0,000 원
            </span>
          </div>
          <div className="col-xs-4 text-left" style={{ paddingLeft: "10px" }}>
            <span className="ng-binding">eo****ger</span>
          </div>
        </li>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    let bestSlots: any = [];
    this.state.slots.map((info: any, index: any) => {
      if (index < 6) {
        bestSlots.push(info);
      }
    });

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
          ></Companylist>
        );
      }

      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      return <div></div>;
    };

    const openBestSlot = (best: any) => {
      if (this.props.authenticated == false) {
        confirmAlert({
          title: "로그인",
          message: "로그인 이후 이용가능한 기능입니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({
                  mode: Mode.none,
                });
              },
            },
          ],
        });
        return;
      }

      if ((best === undefined ? "n" : best.used) === "y") {
        this.setState({
          gameCode: best === undefined ? "" : best.code,
        });
        this.setState({
          gameName: best === undefined ? "" : best.nameKo,
        });
        this.setState({
          gameEName: best === undefined ? "" : best.nameEn,
        });
        this.setState({ mode: Mode.slot });
        this.setState({ popupStatuses: popupView.game });
      } else {
        confirmAlert({
          title: "점검중",
          message: "현재 해당게임은 점검중입니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    };

    return (
      <>
        <div className="main_best_wrap">
          <div className="main_best_box">

          <div className="main_best_title">
              {this.state.activeMode === 'slot' ? <img src="images/sel_slot_active.png" onClick={()=> this.setState({activeMode :'slot'})} /> : <img src="images/sel_slot.png" onClick={()=> this.setState({activeMode :'slot'})}  />}
              {this.state.activeMode === 'casino' ? <img src="images/sel_casino_active.png" onClick={()=> this.setState({activeMode :'casino'})} /> : <img src="images/sel_casino.png" onClick={()=> this.setState({activeMode :'casino'})} />}
                  
            </div>

            {/* <div className="main_best_title">
              <img src="images/slot_title.png" />
            </div> */}

            <div className="game_list_wrap">
              <ul>
                {this.state.activeMode === 'slot' && this.state.slots.map((info: any, index: any) => (
                  

                        <li  key={`key${info.nameEn}kkk`}>
                        <a
                          onMouseOver={() => this.setState({ favSlot1: true })}
                          onMouseOut={() => this.setState({ favSlot1: false })}
                          key={
                            bestSlots[0] === undefined
                              ? ""
                              : `key${bestSlots[0].nameEn}kkk`
                          }
                          onClick={() => {
                            openBestSlot(info);
                          }}
                        >
                          <img
                            src={
                              this.state.favSlot1
                                ? `${info.mobileImg}`
                                : `${info.mobileImg}`
                            }
                            className={this.state.favSlot1 ? "mouseover2" : ""}
                          />
                        </a>
                      </li>
                ))}

                {this.state.activeMode === 'casino' &&  this.state.casionos.map((info: any, index: any) => (
                  

                  <li  key={`key${info.nameEn}kkk`}>
                  <a
                    onMouseOver={() => this.setState({ favSlot1: true })}
                    onMouseOut={() => this.setState({ favSlot1: false })}
                    key={
                      bestSlots[0] === undefined
                        ? ""
                        : `key${bestSlots[0].nameEn}kkk`
                    }
                    onClick={() => {
                      this.handleOpenSlot(info.code, info.gameCompany);
                    }}
                  >
                    <img
                      src={
                        this.state.favSlot1
                          ? `${info.mobileImg}`
                          : `${info.mobileImg}`
                      }
                      className={this.state.favSlot1 ? "mouseover2" : ""}
                    />
                  </a>
                </li>
                ))}
              </ul>


              
            </div>

     

          </div>
        </div>

        <div className="all_title_wrap">
          <div className="all_title_box">
            <div className="all_title">
              {/* <img src="images/all_title.png" /> */}
              <span className="all_title_jackpot">
                <img
                  src="images/all_jackpot.png"
                  style={{ marginLeft: "-400%" }}
                />
                <iframe
                  src="/odometer.html"
                  frameBorder={0}
                  style={{
                    position: "absolute",
                    marginLeft: "-550px",
                    marginTop: "5px",
                    width: "900px",
                    height: "80px",
                  }}
                ></iframe>
          
              </span>
            </div>
          </div>
        </div>

        <div className="all_wrap">
          <div className="all_box">
            <div className="con_box00">
              {/* <div className="box_wrap02">
                <div className="box_box02">
                  <div className="box02">
                    <img src="images/game_slot56.png" width="120" />
                  </div>
                  <div className="box022">빅타임</div>
                </div>
              </div> */}
              {/* {this.RenderSlotList()} */}
            </div>
          </div>
        </div>

       
        {RenderPopup()}
      </>
    );
  }
}
