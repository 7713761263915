import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";
import { ShareMenu } from "./share_menu";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
  menu = "menu",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  point: string;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: "0",
      point: "0",
    };
  }

  handleChangeToBalance = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="close_box" style={{ textAlign: "center" }}>
              <a
                href="javascript:;"
                className="sub_pop1_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                <img src="images/popup_close.png" />
              </a>
            </div>
            <div
              id="contents_wrap"
              style={{ background: "url(/images/bg.jpg) center top no-repeat" }}
            >
              <div className="title_wrap">
                <div className="title">금고</div>
              </div>
              <div className="con_box10">
                <div className="money">
                  <ul>
                    <li>
                      <img
                        src="images/ww_icon.png"
                        style={{ height: "20px" }}
                      />{" "}
                      보유머니 :{" "}
                      <span className="font05" id="myWallet2">
                        {ConverMoeny(this.props.user.balance)}
                      </span>
                      &nbsp;&nbsp;
                    </li>
                    <li>
                      <img
                        src="images/ww_icon2.png"
                        style={{ height: "20px" }}
                      />{" "}
                      금고머니 :{" "}
                      <span className="font05" id="myCas2">
                        {ConverMoeny(this.props.user.point)}
                      </span>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="con_box10">
                <table
                  cellSpacing={0}
                  cellPadding={0}
                  className="write_title_top"
                  style={{ width: "100%", border: "0" }}
                >
                  <tbody>
                    <tr>
                      <td className="write_title">입금신청</td>
                      <td className="write_basic">
                        <input
                          id="deposit_amount"
                          className="input1 numeric.decimalPlaces"
                          size={30}
                          placeholder="0"
                          value={this.state.point}
                          onFocus={() => this.setState({ point: "" })}
                          onChange={(e) =>
                            this.setState({
                              point: e.target.value,
                            })
                          }
                        />
                        <table
                          cellSpacing={2}
                          cellPadding={0}
                          className="write_title_top"
                          style={{
                            width: "100%",
                            border: "0",
                            textAlign: "center",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={this.handleChangeToBalance}
                                >
                                  <span className="m_btn1_1">입금하기</span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">출금신청</td>
                      <td className="write_basic">
                        <input
                          id="deposit_amount"
                          className="input1 numeric.decimalPlaces"
                          size={30}
                          placeholder="0"
                          value={this.state.point}
                          onFocus={() => this.setState({ point: "" })}
                          onChange={(e) =>
                            this.setState({
                              point: e.target.value,
                            })
                          }
                        />
                        <table
                          cellSpacing={2}
                          cellPadding={0}
                          className="write_title_top"
                          style={{
                            width: "100%",
                            border: "0",
                            textAlign: "center",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={this.handleChangeToPoint}
                                >
                                  <span className="m_btn1_1">출금하기</span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="footer_wrap"
              style={{ background: "rgb(0, 60, 123)" }}
            >
              <img src="images/footer.png" style={{ width: "320px" }} />
            </div>
          </>
        )}
      </Popup>
    );
  }
}
