import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";

import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw);
                          }}
                        >
                          <span>출금신청</span>
                        </a>
                      </li>
                      {/* <li className="tab3">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.point);
                          }}
                        >
                          <span>금고</span>
                        </a>
                      </li> */}
                      <li className="tab4 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.notice);
                          }}
                        >
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.help);
                          }}
                        >
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.note);
                          }}
                        >
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">마이페이지 </div>
                      <div className="con_box10">
                        <div className="money">
                          <ul>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon.png" height="26" />{" "}
                              보유머니 :{" "}
                              <span className="font05" id="myWalletPop">
                                {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon2.png" height="26" />{" "}
                              콤프 :{" "}
                              <span className="font05" id="myCasPop">
                                {ConverMoeny(this.props.user.rolling)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>

                      <div className="con_box10">
                        <table
                          style={{ width: "100%", border: 0 }}
                          cellSpacing={0}
                          cellPadding={0}
                          className="write_title_top"
                        >
                          <tbody>
                            <tr>
                              <td className="write_title">아이디</td>

                              <td className="write_basic">
                                <input
                                  className="input1"
                                  size={30}
                                  value={this.props.user.id}
                                  readOnly
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="write_title">예금주명</td>

                              <td className="write_basic">
                                <input
                                  className="input1 userName"
                                  size={30}
                                  readOnly
                                  value={this.props.user.bankowner}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="write_title">회원은행</td>

                              <td className="write_basic">
                                <input
                                  className="input1 userBankName"
                                  size={30}
                                  readOnly
                                  value={this.props.user.bankname}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="write_title">출금계좌번호</td>

                              <td className="write_basic">
                                <input
                                  className="input1 userBankName"
                                  size={30}
                                  readOnly
                                  value={this.props.user.banknum}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="write_title">새 비밀번호</td>

                              <td className="write_basic">
                                <input
                                  type="password"
                                  className="input1 userBankName"
                                  size={30}
                                  placeholder="새 비밀번호"
                                  value={this.state.pass1}
                                  onChange={(e: any) => {
                                    this.setState({ pass1: e.target.value });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="write_title">비밀번호 확인</td>

                              <td className="write_basic">
                                <input
                                  type="password"
                                  className="input1 userBankName"
                                  size={30}
                                  placeholder="비밀번호 확인"
                                  value={this.state.pass2}
                                  onChange={(e: any) => {
                                    this.setState({ pass2: e.target.value });
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="con_box20">
                        <div className="btn_wrap_center">
                          <ul>
                            <li style={{ width: "30%" }}>
                              <a
                                href="javascript:;"
                                style={{ width: "30%" }}
                                onClick={this.handleExchangeToPassword}
                              >
                                <span
                                  className="btn3_1"
                                  style={{ width: "30%" }}
                                >
                                  비밀번호변경
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="ngdialog-content"
              role="document"
              style={{ minWidth: "700px" }}
            >
              <div
                className="ngdialog-wallet-page ngdialog-main-default-page ng-scope"
                ng-controller="WalletController"
                ng-init="setTab(selectWalletTab)"
              >
                <div className="ngdialog__heading">
                  <h4
                    className="ngdialog__title text-center"
                    style={{ outline: "0px" }}
                  >
                    <img src="/common/images/logo.png" alt="" />
                  </h4>
                </div>
                <div className="ngdialog__content">
                  <ul className="ngdialog-main-nav list-inline clearfix">
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.notice);
                      }}
                    >
                      <span className="ng-scope">공지사항</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      <span className="ng-scope">고객센터</span>
                    </li>
                    <li className="active">
                      <span className="ng-scope">마이페이지</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.note);
                      }}
                    >
                      <span className="ng-scope">쪽지</span>
                    </li>
                  </ul>

                  <div className="ngdialog-main-default__content deposit ng-scope">
                    <div className="deposit manual">
                      <div className="balance-overflow">
                        <div
                          style={{ marginRight: "5px" }}
                          className="ng-scope"
                        >
                          <div className="balance__content">
                            <span>
                              <strong className="text-uppercase text-primary ng-scope">
                                보유머니
                              </strong>
                            </span>
                            <strong className="text-uppercase text-primary pull-right ng-binding">
                              {ConverMoeny(this.props.user.balance)} 원
                            </strong>
                          </div>
                          <div className="balance__content">
                            <span>
                              <strong className="text-uppercase text-primary ng-scope">
                                금고머니
                              </strong>
                            </span>
                            <strong className="text-uppercase text-primary pull-right ng-binding">
                              {ConverMoeny(this.props.user.point)} 원
                            </strong>
                          </div>
                        </div>
                      </div>
                      <form className="ng-pristine ng-valid-maxlength ng-valid ng-valid-required">
                        <fieldset>
                          <label>
                            <span className="ng-scope">아이디</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">예금주명</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">회원은행</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">출금계좌번호</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.banknum}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">새 비밀번호</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              placeholder="새 비밀번호"
                              value={this.state.pass1}
                              onChange={(e: any) => {
                                this.setState({ pass1: e.target.value });
                              }}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">비밀번호 확인</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              placeholder="비밀번호 확인"
                              value={this.state.pass2}
                              onChange={(e: any) => {
                                this.setState({ pass2: e.target.value });
                              }}
                            />
                          </div>
                        </fieldset>

                        <div className="text-center">
                          <button
                            className="btn btn-primary btn-block ng-scope"
                            onClick={this.handleExchangeToPassword}
                          >
                            비밀번호변경
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ngdialog-close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
