import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

interface Props {}
interface State {
  notices: any;
  widhdraws: any;
}

export class Footer extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      widhdraws: [],
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일 22시28분";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr },
      { id: "jvd****", price: "83,000,000 원", date: yesterdayStr },
      { id: "vhr**", price: "82,000,000 원", date: yesterdayStr },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr },
      { id: "df****", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  RenderNotice = () => {
    let notices: any = [];

    this.state.notices.map((info: any, index: any) => {
      if (index < 5) {
        notices.push(info);
      }
    });

    return (
      <>
        {notices.map((info: any, index: any) => (
          <tr>
            <td>
              <span className="text-yellow">[공지]</span> {info.title}
            </td>
            <td className="date">{ConvertDate(info.regdate)}</td>
          </tr>
        ))}
      </>
    );
  };

  RenderWidhdraws = () => {
    return (
      <>
        {this.state.widhdraws.map((info: any, index: any) => (
          <li className="tr">
            <div className="user">
              <span className="bullet"></span>
              <span>{info.id}</span>
            </div>
            <div className="amount">
              <span>{info.price}</span>
            </div>
            <div className="date">
              <span>{info.date}</span>
            </div>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <>
        <div className="customer_wrap">
          <div className="customer_box">
            <div className="customer">
              <img src="images/customer_title.png" />
              <br />
              <a
                href="https://www.google.com/intl/ko_kr/chrome/"
                target="_blank"
              >
                <img src="images/customer_chrome.png" />
              </a>
            </div>
            <div className="customer_sns">
              <ul>
                <li></li>
                {/* <li>
                  <img src="images/sns_phone.png" width="80" />
                  &nbsp;&nbsp; 070-0000-0000
                </li>
                <li>
                  <a href="javascript:;" className="casino_1_open">
                    <img src="images/sns_partner.png" width="80" />
                    &nbsp;&nbsp; 파트너제휴
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_wrap">
          <img src="images/footer.png" style={{ width: "320px" }} />
        </div>
      </>
    );
  }
}
