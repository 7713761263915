import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  menu = "menu",
}

const CustomTableCell = styled(TableCell)``;

const CustomTableCell2 = styled(TableCell)``;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();


interface Props {
  handleClosePopup: () => any;
  handleChange: (viewName: string) => any;
  handleClose: () => any;

  note: any;
}

interface State {
  helps: any;
  note: any;
  mode: string;
  regDate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
  status: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      note: this.props.note,
      mode: noteView.list,
      title: "",
      contents: "",
      regDate: "",
      isRead: false,
      text: "",
      status: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotes();
  }


  
  handleGetNotes = () => {
    this.userService.get_user_note().then((data: any) => {
      console.log("## handleGetNotes ##");
      console.log(data);
      if (data.status === "success") {
        this.setState({
          note: data.note,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNote = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regDate: info.regDate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  nextNote = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regDate: info.regDate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  render() {
    let note: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.note.length;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.note.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        note.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <div className="con_box05">
            <table
              cellSpacing={0}
              cellPadding={0}
              className="write_title_top"
              style={{ width: "100%", border: "0" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "10%" }} className="list_title1">
                    번호
                  </td>
                  <td className="list_title1">제목</td>
                  <td style={{ width: "20%" }} className="list_title1">
                    작성일
                  </td>
                  <td style={{ width: "20%" }} className="list_title1">
                    상태
                  </td>
                </tr>
                {note.map((info: any, index: any) => (
                  <>
                    <tr
                      onClick={() => {
                        service.do_read_note(info._id);
                        this.setState({
                          title: info.title,
                          contents: info.text,
                          regDate: info.regDate,
                          isRead: info.isRead,
                          text: info.text,
                          mode: noteView.view,
                          index: index,
                          no: info.no,
                          status: info.isRead === "y" ? "확인" : "미확인",
                        });
                      }}
                    >
                      <td className="list1">{info.no}</td>
                      <td className="list2">
                        <a href="javascript:;">{info.title}</a>
                      </td>
                      <td className="list1">{ConvertDate(info.regDate)}</td>
                      <td className="list1">
                        {info.isRead === "y" ? "확인" : "미확인"}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="page_wrap">
              <div
                id="paging"
                style={{ textAlign: "center" }}
                className="paging"
              >
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: 1,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_ll.gif"
                  />
                </a>
                &nbsp;&nbsp;
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: prevPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_l.gif"
                  />
                </a>
                {pages.map((page: any) => {
                  if (page.no === this.state.page) {
                    return (
                      <>
                        <span
                          style={{ color: "rgb(255, 255, 255)" }}
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          <strong style={{ fontWeight: 600 }}>
                            &nbsp;{page.no}&nbsp;
                          </strong>
                        </span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <span
                          style={{ color: "rgb(255, 255, 255)" }}
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          &nbsp;{page.no}&nbsp;
                        </span>
                      </>
                    );
                  }
                })}
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: nextPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_r.gif"
                  />
                </a>
                &nbsp;&nbsp;
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: totPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_rr.gif"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          <div className="con_box05">
            <table
              className="write_title_top"
              cellSpacing={0}
              cellPadding={0}
              style={{ width: "100%", border: "0" }}
            >
              <tbody>
                <tr>
                  <td className="view1">{this.state.title}</td>
                </tr>
                <tr>
                  <td className="view1 font03">
                    <span className="view_box">글쓴이</span>운영자 &nbsp;{" "}
                    <span className="view_box">작성일</span>{" "}
                    {ConvertDate(this.state.regDate)}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="view2">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>
                    <br />
                    <br />
                 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <div className="comment-box ng-scope">
            <div className="comment-box__header">
              <span className="comment-box__title ng-binding">
                {this.state.title}
              </span>
              <span className="comment-box__date ng-binding">
                {ConvertDate(this.state.regDate)}
              </span>
            </div>
            <div className="comment-box__content">
              <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
            </div>
          </div>

          {note.map((info: any, index: any) => (
            <>
              <div
                className="customer__content ng-scope"
                onClick={() => {
                  service.do_read_note(info._id);
                  this.setState({
                    title: info.title,
                    contents: info.contents,
                    regDate: info.regDate,
                    isRead: info.isRead,
                    text: info.text,
                    mode: noteView.view,
                    index: index,
                    no: info.no,
                    status: info.isRead === "y" ? "확인" : "미확인",
                  });
                }}
              >
                <div className="customer__content__title ng-binding">
                  {info.title}
                </div>
                <div className="customer__content__date pull-right ng-binding">
                  {ConvertDate(info.regDate)}
                </div>
              </div>
            </>
          ))}
          <ul className="pagination-sm pagination ng-isolate-scope ng-valid">
            <li className="pagination-first ng-scope disabled">
              <a
                href="javascript:;"
                className="ng-binding"
                onClick={() => {
                  this.setState({
                    page: 1,
                  });
                }}
              >
                «
              </a>
            </li>
            <li className="pagination-prev ng-scope disabled">
              <a href="javascript:;" className="ng-binding">
                ‹
              </a>
            </li>

            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <li className="pagination-page ng-scope active">
                      <a
                        href="javascript:;"
                        className="ng-binding"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li className="pagination-page ng-scope">
                      <a
                        href="javascript:;"
                        className="ng-binding"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              }
            })}

            <li className="pagination-next ng-scope">
              <a href="javascript:;" className="ng-binding">
                ›
              </a>
            </li>
            <li className="pagination-last ng-scope">
              <a
                href="javascript:;"
                className="ng-binding"
                onClick={() => {
                  this.setState({
                    page: totPage,
                  });
                }}
              >
                »
              </a>
            </li>
          </ul> */}
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div>
              <div className="close_box" style={{ textAlign: "center" }}>
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="images/popup_close.png" />
                </a>
              </div>
              <div
                id="contents_wrap"
                style={{
                  background: "url(/images/bg.jpg) center top no-repeat",
                }}
              >
                <div className="title_wrap">
                  <div className="title">쪽지</div>
                </div>

                {RenderList()}
                {RenderView()}
              </div>
            </div>

            <div
              className="footer_wrap"
              style={{ background: "rgb(0, 60, 123)" }}
            >
              <img src="images/footer.png" style={{ width: "320px" }} />
            </div>
          </>
        )}
      </Popup>
    );
  }
}
