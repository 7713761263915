import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  menu = "menu",
  comp = "comp",
}

interface Props {
  handleClose: () => any;
  changePopup: (value: any) => any;
  handleChange: (viewName: string) => any;
  tryLoginOut: () => any;
  user: any;
}

interface State {
  ID: string;
  PW: string;
}

export class Menu extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="ngdialog ngdialog-theme-default ngdialog-custom ngdialog-wallet ng-scope">
              {/* <div className="ngdialog-overlay"></div> */}
              <div className="ngdialog-content" role="document">
                <div className="ngdialog-wallet-container">
                  <div className="ngdialog__header">
                    <div className="ngdialog__header__title">
                      <h1 id="ngdialog3-aria-labelledby" className="ng-scope">
                        내 계정
                      </h1>
                    </div>
                    <div className="ngdialog-close-container">
                      <a
                        className="ngdialog-close-btn"
                        onClick={() => {
                          this.props.handleClose();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                  <div className="my-wallet user-info">
                    <div className="text-center">
                      <strong>{this.props.user.id}</strong>
                      <i
                        className="guest-dm fa fa-envelope"
                        onClick={() => {
                          this.props.handleChange(popupView.note); //
                        }}
                      >
                        {" "}
                        <span className="ng-binding">0</span>
                      </i>
                    </div>
                    <div className="pull-right">
                      <span className="get-balance">
                        <i className="glyphicon glyphicon-refresh"></i>
                      </span>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="balance">
                    <ul className="balance__list list-unstyled">
                      <li className="balance__items--total-balance balance__items">
                        <div className="balance__content">
                          <p className="balance__content__gspName ng-scope">
                            보유 머니
                          </p>
                          <div className="balance__content__amount">
                            <strong className="balance-amount ng-binding">
                              {ConverMoeny(this.props.user.balance + this.props.user.in_balance)} 원
                            </strong>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="balance"></div>

                  <div className="balance"></div>
                  <div
                    className="ngdialog__content ng-scope"
                    ng-if="aff_user=='N'"
                  >
                    <div className="btn-group btn-group-justified" role="group">
                      <div className="btn-group" role="group">
                        <button
                          className="btn btn-black btn-block ng-scope"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit); //
                          }}
                        >
                          입금신청
                        </button>
                      </div>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-black btn-block ng-scope"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw); //
                          }}
                        >
                          출금신청
                        </button>
                      </div>
                    </div>
                    <div className="btn-group btn-group-justified" role="group">
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-black btn-block ng-scope"
                          onClick={() => {
                            this.props.handleChange(popupView.point); //
                          }}
                        >
                          금고
                        </button>
                      </div>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-black ng-scope"
                          onClick={() => {
                            this.props.handleChange(popupView.comp); //
                          }}
                        >
                          콤프
                        </button>
                      </div>
                    </div>

                    <div className="btn-group btn-group-justified" role="group">
                      <div className="btn-group ng-scope">
                        <button
                          type="button"
                          className="btn btn-black ng-scope"
                          onClick={() => {
                            this.props.handleChange(popupView.notice); //
                          }}
                        >
                          공지사항
                        </button>
                      </div>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-black"
                          onClick={() => {
                            this.props.handleChange(popupView.help); //
                          }}
                        >
                          <span className="ng-scope">고객센터</span>
                        </button>
                      </div>
                    </div>

                    <div className="btn-group btn-group-justified" role="group">
                      <div className="btn-group ng-scope ng-hide">
                        <button
                          type="button"
                          className="btn btn-black"
                          onClick={() => {
                            this.props.handleChange(popupView.user); //
                          }}
                        >
                          <span className="ng-scope">마이페이지</span>
                        </button>
                      </div>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-black ng-scope"
                          onClick={() => {
                            this.props.tryLoginOut();
                          }}
                        >
                          로그아웃
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
