import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  menu = "menu",
}

interface Props {
  gameCode: string;
  gameName: string;
  gameEName: string;
  gameList: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
  gameCode: string;
  gameName: string;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
      gameCode: this.props.gameCode,
      gameName: this.props.gameName,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.state.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGameList = (info: any) => {
    return (
      <>
        <div
          className={
            info.code === this.state.gameCode
              ? "slots-nav ng-scope active"
              : "slots-nav ng-scope"
          }
          onClick={() => {
            this.setState({ gameCode: info.code });
            this.setState({ gameName: info.nameKo });
            this.componentDidMount();
          }}
        >
          <span className="ng-binding">{info.nameKo}</span>
        </div>
      </>
    );
  };

  RenderGame = (info: any, index: any) => {
    let flg1 = index % 4;
    let flg2 = index / 4;

    if (flg1 !== 0) {
      return <></>;
    }

    return (
      <>
        <table
          cellSpacing={0}
          cellPadding={0}
          style={{ width: "100%", border: "0" }}
        >
          <tbody>
            <tr>
              {this.state.games.map((row: any, index: any) =>
                this.RenderGame2(row, index, flg2)
              )}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  RenderGame2 = (info: any, index: any, flg2: any) => {
    if (Math.floor(index / 4) !== flg2) {
      return <></>;
    }
    return (
      <>
        <td style={{ width: "20%" }}>
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{
              width: "100%",
              border: "0",
              margin: "0 auto",
            }}
          >
            <tbody>
              <tr>
                <td style={{ paddingTop: "20px" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.handleOpenSlot(info.code, info.gameCompany);
                      }}
                    >
                      <img
                        src={info.imgUrl}
                        style={{ width: "95%", height: "75px" }}
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ height: "30px" }}>
                  <div
                    style={{
                      textAlign: "center",
                      verticalAlign: "top",
                      height: "30px",
                      padding: "5px 2px 8px 8px",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        verticalAlign: "top",
                      }}
                    >
                      {info.nameKo}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div>
              <div className="close_box" style={{ textAlign: "center" }}>
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="images/popup_close.png" />
                </a>
              </div>
              <div
                id="contents_wrap"
                style={{
                  background: "#000",
                }}
              >
                <div className="title_wrap">
                  <div className="title">{this.props.gameName}</div>
                </div>

                <div className="slot_list">
                  {this.state.games.map((row: any, index: any) =>
                    this.RenderGame(row, index)
                  )}
                </div>
              </div>
            </div>

            {/* <div className="ngdialog ngdialog-theme-default ngdialog-custom ng-scope">
              <div className="ngdialog-content" role="document">
                <div className="ngdialog-gamespopup">
                  <div className="ngdialog__header">
                    <div className="ngdialog__header__title">
                      <h1 id="ngdialog4-aria-labelledby" className="ng-scope">
                        SLOT
                      </h1>
                    </div>
                    <div className="ngdialog-close-container">
                      <a
                        className="ngdialog-close-btn"
                        onClick={() => {
                          this.props.handleClose();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                  <div className="main-content">
                    <main className="m-0">
                      <div className="slots slots-view-page clearfix ng-scope">
                        <div className="slots-nav-container">
                          {this.props.gameList.map((row: any) =>
                            this.RenderGameList(row)
                          )}
                        </div>
                        <div className="slot-container">
                          <div
                            className="mobile-slot-wrapper"
                            style={{ display: "block" }}
                          >
                            <ul
                              className="responsive-thumbnails"
                              id="responsive-thumbnails-4jdyjoi4o"
                            >
                              {this.state.games.map((row: any) =>
                                this.RenderGame(row)
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
