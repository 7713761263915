import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)``;

const CustomTableCell2 = styled(TableCell)``;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className="ng-scope" key={row._id}>
        <CustomTableCell2 className="width12 text-center ng-binding">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
          className="text-left width70"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {row.isRead === "y" ? "확인" : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClosePopup: () => any;
  handleChange: (viewName: string) => any;
  handleClose: () => any;

  note: any;
}

interface State {
  helps: any;
  note: any;
  mode: string;
  regdate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
  status: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      note: this.props.note,
      mode: noteView.list,
      title: "",
      contents: "",
      regdate: "",
      isRead: false,
      text: "",
      status: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    //this.handleGetNotes();
  }

  handleGetNotes = () => {
    this.userService.get_user_note().then((data: any) => {
      console.log("## handleGetNotes ##");
      console.log(data);
      if (data.status === "success") {
        this.setState({
          note: data.note,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNote = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  nextNote = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  render() {
    let note: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.note.length;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.note.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        note.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <div className="con_box10">
            <table
              style={{ width: "100%", border: 0 }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td style={{ width: "10%" }} className="list_title1">
                    번호
                  </td>
                  <td className="list_title1">제목</td>
                  <td style={{ width: "10%" }} className="list_title1">
                    작성일
                  </td>
                  <td style={{ width: "10%" }} className="list_title1">
                    상태
                  </td>
                </tr>

                {note.map((info: any, index: any) => (
                  <>
                    <tr
                      onClick={() => {
                        service.do_read_note(info._id);
                        this.setState({
                          title: info.title,
                          contents: info.contents,
                          regdate: info.regdate,
                          isRead: info.isRead,
                          text: info.text,
                          mode: noteView.view,
                          index: index,
                          no: info.no,
                          status: info.isRead === "y" ? "확인" : "미확인",
                        });
                      }}
                    >
                      <td className="list_notice1">{info.no}</td>
                      <td className="list_notice2">
                        <a href="javascript:;">{info.title}</a>
                      </td>

                      <td className="list_notice1">
                        {ConvertDate(info.regdate)}
                      </td>
                      <td className="list_notice1">
                        {info.isRead === "y" ? "확인" : "미확인"}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="page_wrap">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: 1,
                      });
                    }}
                  >
                    <span className="page">&lt;&lt;</span>
                  </a>
                  &nbsp;
                </li>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: prevPage,
                      });
                    }}
                  >
                    <span className="page">&lt;</span>
                  </a>
                  &nbsp;
                </li>

                {pages.map((page: any) => {
                  if (page.no === this.state.page) {
                    return (
                      <>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({
                                page: page.no,
                              });
                            }}
                          >
                            <span className="pageon">{page.no}</span>
                          </a>
                        </li>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({
                                page: page.no,
                              });
                            }}
                          >
                            <span className="page">{page.no}</span>
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                <li>
                  &nbsp;
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: nextPage,
                      });
                    }}
                  >
                    <span className="page">&gt;</span>
                  </a>
                </li>
                <li>
                  &nbsp;
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: totPage,
                      });
                    }}
                  >
                    <span className="page">&gt;&gt;</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <table className="history-table table">
            <tbody>
              <tr>
                <th className="width12 ng-scope">번호</th>
                <th className="text-left width50 ng-scope">내용</th>
                <th className="ng-scope">날짜</th>
                <th className="ng-scope">상태</th>
              </tr>
              {note.map((info: any, index: any) => (
                <>
                  <tr ng-repeat="announce in filteredPage" className="ng-scope">
                    <td ng-bind="announce.num" className="width12 ng-binding">
                      {info.no}
                    </td>
                    <td className="text-left width50">
                      <a
                        href="javascript:;"
                        className="ng-binding"
                        onClick={() => {
                          service.do_read_note(info._id);
                          this.setState({
                            title: info.title,
                            contents: info.contents,
                            regdate: info.regdate,
                            isRead: info.isRead,
                            text: info.text,
                            mode: noteView.view,
                            index: index,
                            no: info.no,
                            status: info.isRead === "y" ? "확인" : "미확인",
                          });
                        }}
                      >
                        {info.title}
                      </a>
                    </td>
                    <td className="ng-binding">{ConvertDate(info.regdate)}</td>
                    <td className="ng-binding">
                      {info.isRead === "y" ? "확인" : "미확인"}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <ul className="pagination-sm pagination ng-isolate-scope ng-valid">
            <li className="pagination-first ng-scope disabled">
              <a
                href="javascript:;"
                className="ng-binding"
                onClick={() => {
                  this.setState({
                    page: 1,
                  });
                }}
              >
                «
              </a>
            </li>
            <li className="pagination-prev ng-scope disabled">
              <a href="javascript:;" className="ng-binding">
                ‹
              </a>
            </li>

            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <li className="pagination-page ng-scope active">
                      <a
                        href="javascript:;"
                        className="ng-binding"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li className="pagination-page ng-scope">
                      <a
                        href="javascript:;"
                        className="ng-binding"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              }
            })}

            <li className="pagination-next ng-scope">
              <a href="javascript:;" className="ng-binding">
                ›
              </a>
            </li>
            <li className="pagination-last ng-scope">
              <a
                href="javascript:;"
                className="ng-binding"
                onClick={() => {
                  this.setState({
                    page: totPage,
                  });
                }}
              >
                »
              </a>
            </li>
          </ul> */}
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          <div className="con_box10">
            <table
              style={{ width: "98.5%", border: "0", marginLeft: "10px" }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td style={{ height: "30px", textAlign: "right" }}>
                    <span className="view_box">글쓴이</span> 운영자
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="view_box">작성일</span>{" "}
                    {ConvertDate(this.state.regdate)}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="view_box">상태</span>{" "}
                    {ConvertDate(this.state.status)}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="view1 write_title_top">{this.state.title}</td>
                </tr>
                <tr>
                  <td className="view2">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="btn_wrap_center">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        mode: noteView.list,
                      });
                    }}
                  >
                    <span className="btn2_1">목록</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw);
                          }}
                        >
                          <span>출금신청</span>
                        </a>
                      </li>
                      {/* <li className="tab3 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.point);
                          }}
                        >
                          <span>금고</span>
                        </a>
                      </li> */}
                      <li className="tab4 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.notice);
                          }}
                        >
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.user);
                          }}
                        >
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.help);
                          }}
                        >
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">쪽지 </div>

                      <div className="contents_in">
                        {RenderList()}
                        {RenderView()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
