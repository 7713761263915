import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
  menu = "menu",
}

export enum noticeView {
  none = "none",
  list = "list",
  view = "view",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2 className="list_notice1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => setOpen(!open)}
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="list_notice2"
        ></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
  mode: string;
  title: string;
  contents: string;
  date: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      notices: [],
      mode: noticeView.list,
      title: "",
      contents: "",
      date: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({
          notices: data.notices,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  nextNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  render() {
    let notices: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.notices.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        notices.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noticeView.list) {
        return <></>;
      }
      return (
        <>
          <div className="con_box05">
            <table
              cellSpacing={0}
              cellPadding={0}
              className="write_title_top"
              style={{ width: "100%", border: "0" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "10%" }} className="list_title1">
                    번호
                  </td>
                  <td className="list_title1">제목</td>
                  <td style={{ width: "20%" }} className="list_title1">
                    작성일
                  </td>
                </tr>
                {notices.map((info: any, index: any) => (
                  <>
                    <tr
                      onClick={() => {
                        this.setState({
                          title: info.title,
                          contents: info.contents,
                          date: info.regdate,
                          index: index,
                          no: info.no,
                          mode: noticeView.view,
                        });
                      }}
                    >
                      <td className="list1">{info.no}</td>
                      <td className="list2">
                        <a href="javascript:;">{info.title}</a>
                      </td>
                      <td className="list1">{ConvertDate(info.regdate)}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="page_wrap">
              <div
                id="paging"
                style={{ textAlign: "center" }}
                className="paging"
              >
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: 1,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_ll.gif"
                  />
                </a>
                &nbsp;&nbsp;
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: prevPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_l.gif"
                  />
                </a>
                {pages.map((page: any) => {
                  if (page.no === this.state.page) {
                    return (
                      <>
                        <span
                          style={{ color: "rgb(255, 255, 255)" }}
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          <strong style={{ fontWeight: 600 }}>
                            &nbsp;{page.no}&nbsp;
                          </strong>
                        </span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <span
                          style={{ color: "rgb(255, 255, 255)" }}
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          &nbsp;{page.no}&nbsp;
                        </span>
                      </>
                    );
                  }
                })}
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: nextPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_r.gif"
                  />
                </a>
                &nbsp;&nbsp;
                <a
                  className="direction"
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: totPage,
                    });
                  }}
                >
                  <img
                    style={{ border: "0" }}
                    className="down"
                    src="/images/sub/bbs_arrow_rr.gif"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noticeView.view) {
        return <></>;
      }
      return (
        <>
          <div className="con_box05">
            <table
              className="write_title_top"
              cellSpacing={0}
              cellPadding={0}
              style={{ width: "100%", border: "0" }}
            >
              <tbody>
                <tr>
                  <td className="view1">{this.state.title}</td>
                </tr>
                <tr>
                  <td className="view1 font03">
                    <span className="view_box">글쓴이</span>운영자 &nbsp;{" "}
                    <span className="view_box">작성일</span>{" "}
                    {ConvertDate(this.state.date)}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="view2">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="con_box10">
            <table
              cellSpacing={2}
              cellPadding={0}
              style={{ width: "100%", border: "0" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "100%" }}>
                    <a
                      href="javascript:;"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.setState({
                          mode: noticeView.list,
                        });
                      }}
                    >
                      <span className="btn2_2" style={{ width: "100%" }}>
                        목록
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div>
              <div className="close_box" style={{ textAlign: "center" }}>
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="images/popup_close.png" />
                </a>
              </div>
              <div
                id="contents_wrap"
                style={{
                  background: "url(/images/bg.jpg) center top no-repeat",
                }}
              >
                <div className="title_wrap">
                  <div className="title">공지사항</div>
                </div>

                {RenderList()}
                {RenderView()}
              </div>
            </div>

            <div
              className="footer_wrap"
              style={{ background: "rgb(0, 60, 123)" }}
            >
              <img src="images/footer.png" style={{ width: "320px" }} />
            </div>
          </>
        )}
      </Popup>
    );
  }
}
