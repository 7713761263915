import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;
  changePopup: (value: any) => any;
  handleChange: (viewName: string) => any;
}

interface State {
  ID: string;
  PW: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="ngdialog ngdialog-theme-default ngdialog-login ng-scope">
              <div className="ngdialog-content">
                <div className="ngdialog-login clearfix ng-scope">
                  <div className="ngdialog__header">
                    <div className="ngdialog__header__title">
                      <h1 className="ng-scope">로그인</h1>
                    </div>
                    <div className="ngdialog-close-container">
                      <a
                        className="ngdialog-close-btn"
                        onClick={() => {
                          this.props.handleClose();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                  <div className="ngdialog__logo text-center">
                    <img src="/mobile/common/images/logo.png" alt="" />
                  </div>
                  <div className="middle__text">
                    <h5 className="ngdialog__title text-center">
                      <strong className="ng-scope">
                        로그인 후 이용하실 수 있습니다.
                      </strong>
                    </h5>
                  </div>
                  <div className="ngdialog__content">
                    <form className="popup-form ng-pristine ng-valid">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="fa fa-user"></i>
                        </span>
                        <input
                          type="text"
                          className="popup-form__element form-control ng-pristine ng-untouched ng-valid"
                          placeholder="아이디"
                          onChange={({ target: { value } }) =>
                            this.setState({ ID: value })
                          }
                        />
                      </div>
                      <div className="input-group second-input">
                        <span className="input-group-addon">
                          <i className="fa fa-lock"></i>
                        </span>
                        <input
                          type="password"
                          className="popup-form__element form-control ng-pristine ng-untouched ng-valid"
                          placeholder="비밀번호"
                          onChange={({ target: { value } }) =>
                            this.setState({ PW: value })
                          }
                        />
                      </div>
                      <button
                        className="btn btn-block btn-primary outline ng-scope"
                        onClick={() => {
                          if (
                            this.state.ID == null ||
                            this.state.ID == "" ||
                            this.state.PW == "" ||
                            this.state.PW == null
                          ) {
                            return;
                          }
                          this.props.tryLogin(this.state.ID, this.state.PW);
                        }}
                      >
                        로그인
                      </button>
                    </form>
                    <button
                      className="btn btn-block btn-primary ng-scope"
                      onClick={() => {
                        this.props.handleChange(popupView.reg); //회원가입
                      }}
                    >
                      회원가입
                    </button>
                  </div>
                  <div className="ngdialog__footer text-center">
                    <p
                      style={{ verticalAlign: "inherit" }}
                      id="ngdialog3-aria-describedby"
                    >
                      아직 계정이 없으신가요?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="modal loginModal fade show"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog size-sm modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>로그인</h5>
                      <span>LOGIN</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-container">
                      <div className="form-head">
                        <span>
                          <i className="fab fa-fort-awesome"></i> Ocean 슬롯에
                          오신것을 환영합니다.
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>- 아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="아이디"
                            onChange={({ target: { value } }) =>
                              this.setState({ ID: value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>- 비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            type="password"
                            placeholder="비밀번호"
                            onChange={({ target: { value } }) =>
                              this.setState({ PW: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button
                          className="login-btn"
                          data-dismiss="modal"
                          onClick={() => {
                            if (
                              this.state.ID == null ||
                              this.state.ID == "" ||
                              this.state.PW == "" ||
                              this.state.PW == null
                            ) {
                              return;
                            }
                            this.props.tryLogin(this.state.ID, this.state.PW);
                          }}
                        >
                          <i className="fas fa-unlock-alt"></i> 로그인
                        </button>
                        <button
                          className="gray-bg"
                          onClick={() => {
                            this.props.handleChange(popupView.reg); //회원가입
                          }}
                        >
                          <i className="fa fa-user-plus"></i> 회원가입
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
