import React, {useEffect, useState} from "react";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import { AxiosService } from "./service/axios.service";
import { AuthService } from "./service/auth.service";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Web } from "./web/index";
import { Web as WebM } from "./web_m/index";
import "./App.css";



let auth = new AuthService();



  function App() {

  const [fixService, setFixService] = useState(false);

  const [cookie, setCookie, removeCookie] = useCookies(["session"]);
  let authenticated: boolean = cookie.session != null;
  let session: any = cookie.session != null;
  let fMobile: Boolean = false;



  const getFixService = async() =>{
    const fix = await  auth.getFixService();
    if(fix.status === 'success'){
      setFixService(fix.isFixService as boolean)
    }else {
      setFixService(true as boolean)
    }
  }
  
  useEffect(() => {
    getFixService()
  });

  if (cookie.mobileYn === undefined) {
    fMobile = isMobile;
  } else {
    fMobile = cookie.mobileYn === "Y" ? true : false;
  }
  setCookie("mobileYn", fMobile ? "Y" : "N", { path: "/" });


  const login = (id: any, pw: any) => {
    auth.doLogin(id, pw).then((s) => {
      if (s.status === "success") {
        if (cookie.session != null) {
          removeCookie("session", { path: "/" });
        }
        setCookie("session", s.session, { path: "/" });
        setCookie("name", id, { path: "/" });
        window.location.href = "/";
      } else if (s.status === "wait") {
        confirmAlert({
          title: "로그인 ",
          message: "가입대기중 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else {
        confirmAlert({
          title: "로그인 ",
          message: "아이디 또는 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                removeCookie("session", { path: "/" });

                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  const logout = () => {
    confirmAlert({
      title: "로그아웃 ",
      message: "로그아웃 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            removeCookie("session", { path: "/" });
          },
        },
      ],
    });
  };

  AxiosService.SetSession(cookie.session);

  if(fixService) {
    return (<div>
      <img src="https://d2ov46plj76ptt.cloudfront.net/public/notice/fixservice.png" style={{width:'100%', height:'100%'}}></img>
    </div>)
  }

  if (isMobile) {
    return (
      <>
        <WebM
          tryLogin={login}
          authenticated={authenticated}
          session={session.session}
          tryLoginOut={() => {
            removeCookie("session", { path: "/" });
            window.location.reload();
          }}
          SetCookie={(name: string, data: any) =>
            setCookie(name, data, { path: "/" })
          }
          GetCookie={(name: string) => cookie[name]}
        ></WebM>

{
        document.write(`<script>doLiveChat("${cookie.name}")</script>`)}
{
          
            document.write(`<script
            src="https://kr03.tocplus007.com/chatLoader.do?userId=btsbts1111"
            type="text/javascript"
            ></script>`)
        }
      </>
    );
  } else {
    return (
      <>
        <Web
          tryLogin={login}
          authenticated={authenticated}
          session={session.session}
          tryLoginOut={() => {
            removeCookie("session", { path: "/" });
            window.location.reload();
          }}
          SetCookie={(name: string, data: any) =>
            setCookie(name, data, { path: "/" })
          }
          GetCookie={(name: string) => cookie[name]}
        ></Web>

{
        document.write(`<script>doLiveChat("${cookie.name}")</script>`)}
{
          
            document.write(`<script
            src="https://kr03.tocplus007.com/chatLoader.do?userId=btsbts1111"
            type="text/javascript"
            ></script>`)
        }
  
      </>
    );
  }
}

export default App;
