import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  id: string;

  nick: string;
  pass: string;
  pass_check: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone: string;

  bankname: string;
  banknumber: string;
  bankowner: string;
  exchange_pw: string;
  code: string;
  showCode: boolean;
}

export class Reg extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      id: "",
      nick: "",
      pass: "",
      pass_check: "",
      phone1: "",
      phone2: "",
      phone3: "",
      phone: "",

      bankname: "",
      banknumber: "",
      bankowner: "",
      exchange_pw: "",
      code: "",
      showCode: false,
    };
  }

  componentDidMount() {
    this.userService.getRegDomain().then((data: any) => {
      if (data.status === "success") {
        this.setState({ code: data.code, showCode: false });
      } else {
        this.setState({ showCode: true });
      }
    });
  }

  handleCheck = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckID(this.state.id).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원 가입",
          message: "사용 할수있는 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "사용 불가능한 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  handleReg = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9])/.test(this.state.pass)
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "비밀번호는 특수문자 영문 숫자 조합으로 생성되어야합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (korean.test(this.state.id)) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디는 한글을 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      this.state.pass == null ||
      this.state.pass_check == null ||
      this.state.pass !== this.state.pass_check
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "비밀번호를 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    let phone = this.state.phone;
    if (phone == null || phone.length <= 10) {
      confirmAlert({
        title: "회원 가입",
        message: "휴대폰 번호를 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.bankname == null) {
      confirmAlert({
        title: "회원 가입",
        message: "은행명을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.banknumber == null) {
      confirmAlert({
        title: "회원 가입",
        message: "계좌 번호를  확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (this.state.bankowner == null) {
      confirmAlert({
        title: "회원 가입",
        message: "예금주 이름을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.userService
      .regUser({
        id: this.state.id.trim(),

        nick: this.state.nick,
        pw: this.state.pass,
        pass_check: this.state.pass_check,
        phone: phone,
        bankname: this.state.bankname,
        banknumber: this.state.banknumber,
        bankowner: this.state.bankowner,
        exchange_pw: this.state.bankowner,
        code: this.state.code,
      })
      .then((data: any) => {
        if (data.status === "alread_have_user") {
          confirmAlert({
            title: "회원 가입",
            message: "생성할수 없는 유저아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "cant_find_code") {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입코드를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });

          return;
        } else if (data.status === "already") {
          confirmAlert({
            title: "회원 가입",
            message: "사용할수 없는 닉네임 또는 아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });

          return;
        }
      });
  };

  

  handleCheckNick = () => {
    if (
      this.state.nick === undefined ||
      this.state.nick.length < 2 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "닉네임 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckNick(this.state.nick).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원 가입",
          message: "사용가능한 닉네임 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "사용 불가능한 닉네임 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
        

<div id="contents_wrap">
<div className="close_box">
                  <a
                    href="javascript:;"
                    className="casino_1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
    <div className="title_wrap">
        <div className="title">회원가입</div>
    </div>
    <div className="con_box05">
        <table className="write_title_top">

            <tbody>
                <tr>
                    <td className="write_title">아이디</td>
                    <td className="write_basic">
                      <input className="input3" type="text" 
                        value={this.state.id}
                        onChange={(e: any) => {
                          this.setState({ id: e.target.value });
                        }}
                    
                      /> 
                        <a onClick={()=> this.handleCheck()}><span className="btn1_1">중복확인</span></a>
                      
                      </td>
                </tr>

                <tr>
                    <td className="write_title">닉네임</td>
                    <td className="write_basic">
                      <input className="input3" name="membername" type="text" id="membername" 
                       value={this.state.nick}
                       onChange={(e: any) => {
                         this.setState({ nick: e.target.value });
                       }}
                      />
                        <a onClick={()=> this.handleCheckNick()}><span className="btn1_1">중복확인</span></a>
                      
                      {/* <a onClick={()=> this.handleCheck()}><span className="btn1_1">중복확인</span></a> */}
                      </td>
                </tr>


                <tr>
                    <td className="write_title">이름</td>
                    <td className="write_basic">
                      <input className="input3" name="membername" type="text" id="membername" 
                           value={this.state.bankowner}
                           onChange={(e: any) => {
                             this.setState({ bankowner: e.target.value });
                           }}
                    
                    /><span><br />( 입금과 출금시 사용하시는 실제 예금주명으로 기입하여 주시기 바랍니다 )</span></td>
                </tr>

                <tr>
                    <td className="write_title">비밀번호</td>
                    <td className="write_basic">
                      <input className="input3" name="memberpw" type="password" id="memberpw" placeholder="6 ~ 16자리 (영문, 숫자)" 
                         value={this.state.pass}
                         onChange={(e: any) => {
                           this.setState({ pass: e.target.value });
                         }}
                      />
                    </td>
                </tr>
                <tr>
                    <td className="write_title">비밀번호 확인</td>
                    <td className="write_basic">
                      <input className="input3" name="confirmpw" type="password" id="confirmpw" 
                        value={this.state.pass_check}
                        onChange={(e: any) => {
                          this.setState({ pass_check: e.target.value });
                        }}
                    /></td>
                </tr>
                <tr>
                    <td className="write_title">은행</td>
                    <td className="write_basic">
                      <input className="input3" name="accountnumber" type="text" id="accountnumber"  placeholder="은행" 
                        value={this.state.bankname}
                        onChange={(e: any) => {
                          this.setState({ bankname: e.target.value });
                        }}
                      />
                       (증권계좌, 저축은행, K뱅크 사용불가)
                    </td>
                </tr>
                <tr>
                    <td className="write_title">계좌번호</td>
                    <td className="write_basic">
                        <input className="input3" name="accountnumber" type="text" id="accountnumber"  placeholder="계좌번호를 입력하세요" 
                          
                          value={this.state.banknumber}
                          onChange={(e: any) => {
                            this.setState({ banknumber: e.target.value });
                          }}
                        />
                        <br /><span>( 띄어쓰기와 - 없이 숫자로만 기입하여 주시기 바랍니다 )</span>
                    </td>
                </tr>
                <tr>
                    <td className="write_title">핸드폰</td>
                    <td className="write_basic">
                        <input className="input3" 
                         type="text"
                              value={this.state.phone}
                              onChange={(e: any) => {
                                this.setState({ phone: e.target.value });
                              }}
                        />
                    </td>
                </tr>



           



                <tr>
                    <td className="write_title">추천코드</td>

                    <td className="write_basic">
                        <input className="input3" name="recomcode_id" type="text" id="recomcode_id" 
                            onChange={(e: any) => {
                              this.setState({ code: e.target.value });
                            }}

                        />
                        <br />
                        <span>


                        </span>
                    </td>
                </tr>




            </tbody>
        </table>
    </div>
    <div className="con_box10">
        <div className="btn_wrap_center">
            <ul>
                <li><a 
                      onClick={() => {
                        this.handleReg();
                      }}
                ><span className="btn3_1">회원가입완료</span></a></li>
            </ul>
        </div>
    </div>
</div>
          </>
        )}
      </Popup>
    );
  }
}
