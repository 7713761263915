import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import Marquee from "react-fast-marquee";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  gameView: string;
  updateUser: () => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  gameEName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
  yesterdayStr: string;
  activeMode :string; 
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      gameEName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
      yesterdayStr: "",
      activeMode : 'casino',
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        console.log(s.slot);
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        // for (const casiono of Object.values(s.casiono) as any) {
        //   if (casiono.nameEn !== "Evolution") {
        //     item.push(casiono);
        //   }
        // }

        this.setState({
          casionos: s.casiono,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    this.setState({ yesterdayStr: yesterdayStr });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <div
            className="box_wrap02 casino_1_open"
            key={`key${info.nameEn}kkk`}
            style={{height : '90px'}}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.setState({ gameEName: info.nameEn });
                this.setState({ mode: Mode.slot });
                // this.handleSlotGames(info.code);
                this.setState({ popupStatuses: popupView.game });
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="box_box02">
              <div className="box02">
                <img src={info.mobileImg} style={{ width: "120px" }} />
              </div>
              <div className="box022">{info.nameKo}</div>
            </div>
          </div>
        ))}
      </>
    );
  };

  RenderSlotList2 = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className={
              this.state.gameCode === info.code
                ? "menu-link active"
                : "menu-link"
            }
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.handleSlotGames(info.code);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="img-cont">{info.nameEn}</div>
            <div className="text-cont">
              <span>{info.nameKo}</span>
            </div>
          </a>
        ))}
      </>
    );
  };

  RenderSlotGameList = () => {
    return (
      <>
        {this.state.slotgames.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className="game-btn"
            onClick={() => {
              this.handleOpenSlot(info.code, info.gameCompany);
            }}
          >
            <div className="main-cont">
              <img className="main-img" src={`${info.imgUrl}`} />
              <button className="play-btn">
                <i className="fa fa-play" aria-hidden="true"></i>
              </button>
            </div>
            <div className="footer">
              <span>{info.nameKo}</span>
            </div>
          </a>
        ))}
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
          {this.state.casionos.map((info: any, index: any) => (
            <div
              className="box_wrap02 casino_1_open"
              key={`key${info.nameEn}kkk`}
              style={{height : '90px'}}
              onClick={() => {
                if (this.props.authenticated === false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            popupStatuses: popupView.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
               <div className="box_box02">
                  <div className="box02">
                    <img src={info.mobileImg} style={{ width: "120px" }} />
                  </div>
                  <div className="box022">{info.nameKo}</div>
                </div>
            </div>
          ))}
      </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== Mode.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices: any = [];
    let widhdraws = this.state.widhdraws;

    this.state.notices.map((info: any, index: any) => {
      if (index < 4) {
        notices.push(info);
      }
    });

    return (
      <>
        <div className="transaction-table">
          <div className="table-heading">
            <span className="col-xs-8">공지사항/이벤트게시판/ 주간출금</span>
            <span
              className="col-xs-4 pull-right text-right"
              onClick={() => {
                this.setState({ popupStatuses: popupView.notice }); //공지사항
              }}
            >
              전체 보기
            </span>
          </div>
          <ul
            className=" list-unstyled transaction-list"
            style={{
              width: "328px",
              height: "159.5px",
              overflow: "hidden",
            }}
            id="notice-ticker"
          >
            {notices.map((info: any, index: any) => (
              <li
                role="button"
                className="ng-scope"
                onClick={() => {
                  this.setState({ popupStatuses: popupView.notice }); //공지사항
                }}
              >
                <div className="col-xs-4 text-left">
                  <span className="ng-binding">
                    {ConvertDate(info.regdate)}
                  </span>
                </div>
                <div className="col-xs-8 text-left text-white">
                  <span className="ng-binding">{info.title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* <div
          className="notice ani"
          animate-effect="zoomIn"
          animate-duration="0.3s"
          animate-delay="0.1s"
        >
          <h2>공지사항</h2>
          <ul>
            {notices.map((info: any, index: any) => (
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  [공지] {info.title}
                </a>
                <span className="date" style={{ color: "#666" }}>
                  {ConvertDate(info.regdate)}
                </span>
              </li>
            ))}
          </ul>
        </div> */}
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    let bestSlots: any = [];
    this.state.slots.map((info: any, index: any) => {
      if (index < 6) {
        bestSlots.push(info);
      }
    });

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
            gameList={this.state.slots}
          ></Companylist>
        );
      }

      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      return <div></div>;
    };

    const openBestSlot = (best: any) => {
      if (this.props.authenticated == false) {
        confirmAlert({
          title: "로그인",
          message: "로그인 이후 이용가능한 기능입니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({
                  mode: Mode.none,
                });
              },
            },
          ],
        });
        return;
      }

      if ((best === undefined ? "n" : best.used) === "y") {
        this.setState({
          gameCode: best === undefined ? "" : best.code,
        });
        this.setState({
          gameName: best === undefined ? "" : best.nameKo,
        });
        this.setState({
          gameEName: best === undefined ? "" : best.nameEn,
        });
        this.setState({ mode: Mode.slot });
        this.setState({ popupStatuses: popupView.game });
      } else {
        confirmAlert({
          title: "점검중",
          message: "현재 해당게임은 점검중입니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    };

    //

    return (
      <>
        <div
          id="banner-slider-page"
          className={
            this.props.authenticated ? "slider_style2" : "slider_style1"
          }
        >
          
        
        </div>

        <div className="main_best_wrap" style={{ position: "relative" }}>
          <div className="main_best_box">
            <div className="main_best_title">
              <img src="images/m_slot_title.png" style={{ width: "280px" }} />
            </div>
            <div className="con_box00">

            <div id="slider">
              <img src="images/m_slideshow1.jpg" alt="" />
              <img src="images/m_slideshow2.jpg" alt="" />
            </div>
          </div>

         
          </div>
          <br/>
            <br/>
            {this.state.activeMode === 'slot' ? <img src="images/sel_slot_active.png" onClick={()=> this.setState({activeMode :'slot'})}  style={{ width: "99.8%" }} /> : <img src="images/sel_slot.png" style={{ width: "99.8%" }}  onClick={()=> this.setState({activeMode :'slot'})}  />}
            {this.state.activeMode === 'casino' ? <img src="images/sel_casino_active.png" onClick={()=> this.setState({activeMode :'casino'})} style={{ width: "99.8%" }}  /> : <img src="images/sel_casino.png" style={{ width: "99.8%" }}  onClick={()=> this.setState({activeMode :'casino'})} />}

        </div> 

     
{/* 
        <div className="all_title_wrap">
          <div className="all_title_box">
            <div className="all_title">
              <img src="images/all_title.png" style={{ width: "320px" }} />
            </div>
          </div>
        </div> */}

        <div className="all_wrap">
          {
            this.state.activeMode === 'slot' ?
            <div className="con_box00">{this.RenderSlotList()}</div>
            :
            <div className="con_box00">{this.RenderCasinoGame()}</div>
          }

        </div>


        {RenderPopup()}

           <div className="jackpot_wrap" style={{ position: "relative" }}>
          <div className="jackpot">
            <img src="images/jackpot.png" style={{ width: "160px" }} />
            <br />
            <iframe
              src="/odometer_m.html"
              frameBorder={0}
              style={{
                border: "0",
                width: "900px",
                height: "80px",
                position: "absolute",
                marginLeft: "-810px",
                marginTop: "-5px",
              }}
            ></iframe>
          </div>
        </div>
      </>
    );
  }
}
