import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  comp = "comp",
  reg = "reg",
  login = "login",
  menu = "menu",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };

    console.log(this.props.user);
  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
      }
    });
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "입금",
        message: "입금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "입금",
        message: "최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "입금",
        message: "입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserDeposit(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "입금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "입금",
          message: "대기중인 입금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "입금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="close_box" style={{ textAlign: "center" }}>
              <a
                href="javascript:;"
                className="sub_pop1_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                <img src="images/popup_close.png" />
              </a>
            </div>
            <div
              id="contents_wrap"
              style={{ background: "url(/images/bg.jpg) center top no-repeat" }}
            >
              <div className="title_wrap">
                <div className="title">입금신청</div>
              </div>
              <div className="con_box10">
                <div className="info_wrap">
                  <div className="info2">주의사항</div>
                  <div className="info3">
                    <span style={{ color: "rgb(255, 0, 0)" }}>
                      <b>
                        - 보이스피싱 및 3자사기로 협박하더라도 협상 및 타협
                        절대없음
                      </b>
                    </span>
                    <br />
                    - 계좌거래시 입금/출금 이름이 동일해야 입출금이 가능하오니
                    정확히 입력해 주세요.
                    <br />- 수표나 타인계좌로 이름변경하여 입금시 머니몰수 및
                    아이디정지 처리되오니 타인명의로 입금해야될시 문의를
                    먼저하시기 바랍니다
                  </div>
                </div>
              </div>
              <div className="con_box10">
                <div className="money">
                  <ul>
                    <li>
                      <img
                        src="images/ww_icon.png"
                        style={{ height: "20px" }}
                      />{" "}
                      보유머니 :{" "}
                      <span className="font05" id="myWallet2">
                        {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                      </span>
                      &nbsp;&nbsp;
                    </li>
                    <li>
                      <img
                        src="images/ww_icon2.png"
                        style={{ height: "20px" }}
                      />{" "}
                      콤프 :{" "}
                      <span className="font05" id="myCas2">
                        {ConverMoeny(this.props.user.rolling)}
                      </span>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => this.handleAsk()}>
                        <span className="btn1_2">
                          <img
                            src="images/icon_re.png"
                            style={{ height: "20px" }}
                          />{" "}
                          입금계좌문의
                        </span>
                      </a>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="con_box10">
                <table
                  cellSpacing={0}
                  cellPadding={0}
                  className="write_title_top"
                  style={{ width: "100%", border: "0" }}
                >
                  <tbody>
                    <tr>
                      <td className="write_title">아이디</td>

                      <td className="write_basic">
                        <input
                          className="input1"
                          size={30}
                          value={this.props.user.id}
                          readOnly
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="write_title">예금주명</td>

                      <td className="write_basic">
                        <input
                          className="input1 userName"
                          size={30}
                          readOnly
                          value={this.props.user.bankowner}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">회원은행</td>

                      <td className="write_basic">
                        <input
                          className="input1 userBankName"
                          size={30}
                          readOnly
                          value={this.props.user.bankname}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="write_title">신청금액</td>
                      <td className="write_basic">
                        <input
                          id="deposit_amount"
                          className="input1 numeric.decimalPlaces"
                          size={30}
                          readOnly
                          placeholder="0"
                          value={this.state.balance}
                          onFocus={() => this.setState({ balance: "" })}
                          onChange={(e) =>
                            this.setState({
                              balance: e.target.value,
                            })
                          }
                        />
                        <table
                          cellSpacing={2}
                          cellPadding={0}
                          className="write_title_top"
                          style={{
                            width: "100%",
                            border: "0",
                            textAlign: "center",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 10000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">1만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 50000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">5만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 100000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">10만원</span>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 300000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">30만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 500000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">50만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 1000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">100만원</span>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 5000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">500만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 10000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="m_btn1_2">1000만원</span>
                                </a>
                              </td>
                              <td style={{ width: "10%" }} align="center">
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({ balance: "0" });
                                  }}
                                >
                                  <span className="m_btn1_1">정정</span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="con_box10">
                <div className="btn_wrap_center">
                  <ul>
                    <li style={{ width: "30%" }}>
                      <a
                        href="javascript:;"
                        style={{ width: "30%" }}
                        onClick={this.handleDoDeposit}
                      >
                        <span className="btn3_1" style={{ width: "30%" }}>
                          입금신청
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="footer_wrap"
              style={{ background: "rgb(0, 60, 123)" }}
            >
              <img src="images/footer.png" style={{ width: "320px" }} />
            </div>
          </>
        )}
      </Popup>
    );
  }
}
