import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Menu } from "./menu";
import { Companylist } from "./companylist";
import Marquee from "react-fast-marquee";
import { ConvertDate } from "../utility/help";
import { DonutSmall } from "@material-ui/icons";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  menu = "menu",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  updateAside: (flag: boolean) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
  notices: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
      notices: [],
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(30000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        // for (const casiono of Object.values(s.casiono) as any) {
        //   if (casiono.nameEn !== "Evolution") {
        //     item.push(casiono);
        //   }
        // }

        this.setState({
          casionos: s.casiono,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      // if (data.status === "success") {
      //   this.setState({
      //     balance: data.balance ?? 0,
      //   });
      //   this.props.user.balance = data.balance ?? 0;
      // } else {
      // }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  RenderNotice = () => {
    return (
      <>
        {this.state.notices.map((info: any, index: any) => (
          <div className="announcement ng-scope">
            <h1 className="announcement__subject ng-binding">* {info.title}</h1>
            <p className="announcement__date ng-binding">
              {ConvertDate(info.regdate)}
            </p>
            <span
              style={{
                color: "#444444",
                fontWeight: 400,
                position: "relative",
              }}
            >
              |
            </span>
          </div>
        ))}
      </>
    );
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.menu) {
        return (
          <Menu
            handleClose={this.handleClosePopup}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
          ></Menu>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="login">
              <ul>
                <li style={{ width: "30%" }}>
                  <input
                    type="text"
                    className="input_login"
                    placeholder="아이디"
                    onChange={({ target: { value } }) =>
                      this.setState({ ID: value })
                    }
                  />
                </li>
                <li style={{ width: "30%" }}>
                  <input
                    type="password"
                    className="input_login"
                    placeholder="비밀번호"
                    onChange={({ target: { value } }) =>
                      this.setState({ PW: value })
                    }
                  />
                </li>
                <li style={{ width: "20%" }}>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      if (
                        this.state.ID == null ||
                        this.state.ID == "" ||
                        this.state.PW == "" ||
                        this.state.PW == null
                      ) {
                        return;
                      }
                      this.props.tryLogin(this.state.ID, this.state.PW);
                    }}
                  >
                    <span className="login_btn01">로그인</span>
                  </a>
                </li>
                <li style={{ width: "20%" }}>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg }); //회원가입
                    }}
                  >
                    <span className="login_btn02">회원가입</span>
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="my_wrap">
            <div className="my">
              <a 
               onClick={() => {
                this.setState({ popupStatuses: popupView.note}); //회원가입
              }}
              >
                <img
                  id="letterimg"
                  src="/images/letter_img.gif"
                  style={{ width: "16px", height: "16px", border: "0" }}
                />
                <span id="letteralarm" style={{ fontSize: "11px" }}></span>
                &nbsp;&nbsp;
              </a>
              <img src="/images/70AQ7_1.jpg" width="20" />
              <span className="font01">{this.props.user.id}</span>님{" "}
              <span style={{ float: "right" }}>
                <img src="images/ww_icon.png" style={{ height: "16px" }} />{" "}
                보유머니{" "}
                <span className="font05">
                  {ConverMoeny(this.props.user.balance +  this.props.user.in_balance)}
                </span>{" "}
                &nbsp;&nbsp;{" "}
                <img src="images/ww_icon2.png" style={{ height: "16px" }} />{" "}
                콤프{" "}
                <span className="font05">
                  {ConverMoeny(this.props.user.rolling)}
                </span>
              </span>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <div id="header_wrap">
          <div className="top">
            <div className="m_logo">
              <a href="javascript:;">
                <img src="images/m_logo.png" width="160" />
              </a>
            </div>
            <div className="m_menu">
              <a
                href="javascript:;"
                onClick={() => {
                  this.props.updateAside(true);
                }}
              >
                <img src="images/m_menu.png" width="40" />
              </a>
            </div>
          </div>

          {RenderLogin()}
        </div>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
