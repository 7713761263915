import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Help } from "./help";
import { Notice } from "./notice";
import { Point } from "./point";
import { Comp } from "./comp";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  menu = "menu",
}

interface Props {
  SetCookie: (name: string, data: any) => any;
  authenticated: boolean;
  updateUser: () => any;
  user: any;
}
interface State {
  notices: any;
  helps: any;
  widhdraws: any;
  selectBoard: string;
  popupStatuses: string;
}

export class Footer extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      helps: [],
      widhdraws: [],
      selectBoard: "1",
      popupStatuses: popupView.none,
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일 22시28분";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr },
      { id: "jvd****", price: "83,000,000 원", date: yesterdayStr },
      { id: "vhr**", price: "82,000,000 원", date: yesterdayStr },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr },
      { id: "df****", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  RenderNotice = () => {
    return (
      <>
        {this.state.notices.map((info: any, index: any) => (
          <li
            className="realtime-event__list__item ng-scope"
            style={{ marginTop: "0px" }}
            onClick={() => {
              this.setState({ popupStatuses: popupView.notice }); //
            }}
          >
            <div className="realtime-event__list__item__subject ng-binding">
              * {info.title}
            </div>
            <div className="realtime-event__list__item__date ng-binding">
              {ConvertDate(info.regdate)}
            </div>
          </li>
        ))}
      </>
    );
  };

  RenderHelp = () => {
    return (
      <>
        {this.state.helps.map((info: any, index: any) => (
          <li
            className="realtime-event__list__item ng-scope"
            style={{ marginTop: "0px" }}
            onClick={() => {
              this.setState({ popupStatuses: popupView.help }); //
            }}
          >
            <div className="realtime-event__list__item__subject ng-binding">
              • {info.title}
            </div>
            <div className="realtime-event__list__item__date ng-binding">
              {ConvertDate(info.regdate)}
            </div>
          </li>
        ))}
      </>
    );
  };

  RenderWidhdraws = () => {
    return (
      <>
        {this.state.widhdraws.map((info: any, index: any) => (
          <li className="tr">
            <div className="user">
              <span className="bullet"></span>
              <span>{info.id}</span>
            </div>
            <div className="amount">
              <span>{info.price}</span>
            </div>
            <div className="date">
              <span>{info.date}</span>
            </div>
          </li>
        ))}
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }

      return <div></div>;
    };

    return (
      <>
        <div className="footer_wrap">
          <img src="images/footer.png" width="320" style={{ width: "320px" }} />
        </div>
      </>
    );
  }
}
