import { Height } from "@material-ui/icons";
import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  gameEName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any, index: any) => {
    let flg1 = index % 8;
    let flg2 = index / 8;

    if (flg1 !== 0) {
      return <></>;
    }

    return (
      <>
        <tr>
          {this.state.games.map((row: any, index: any) =>
            this.RenderGame2(row, index, flg2)
          )}
        </tr>
        <tr>
          <td style={{ height: "12px" }}></td>
        </tr>
      </>
    );
  };

  RenderGame2 = (info: any, index: any, flg2: any) => {
    if (Math.floor(index / 8) !== flg2) {
      return <></>;
    }
    return (
      <>
        <td>
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ width: "100%", border: 0 }}
          >
            <tbody>
              <tr>
                <td>
                  <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{ width: "100%", border: 0 }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <div style={{ textAlign: "center" }}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.handleOpenSlot(
                                  info.code,
                                  info.gameCompany
                                );
                              }}
                            >
                              <img
                                src={info.imgUrl}
                                style={{
                                  // filter: "alpha(opacity=50)",
                                  // opacity: "0.2",
                                  width: "140px",
                                  height: "140px",
                                }}
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: "30px" }}>
                          <div
                            style={{
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {info.nameKo}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="popup_style02 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="casino_1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox" id="popupbox_ajax">
                  <div>
                    <div className="con_box10">
                      <div className="title1">{this.props.gameName}</div>
                    </div>
                    <div style={{ width: "100%", marginTop: "-5px" }}>
                      <table
                        cellSpacing={0}
                        cellPadding={0}
                        style={{ width: "100%", border: 0 }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ height: "20px" }}>&nbsp;</td>
                          </tr>
                          {this.state.games.map((row: any, index: any) =>
                            this.RenderGame(row, index)
                          )}
                          <tr>
                            <td style={{ height: "20px" }}>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
