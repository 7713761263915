import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleDoWithdraw = () => {
    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      console.log(data);
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message:
            "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>출금신청</span>
                        </a>
                      </li>
                      {/* <li className="tab3">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.point);
                          }}
                        >
                          <span>금고</span>
                        </a>
                      </li> */}
                      <li className="tab4">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.notice);
                          }}
                        >
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.user);
                          }}
                        >
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.help);
                          }}
                        >
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.note);
                          }}
                        >
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">출금신청 </div>
                      <div className="con_box10">
                        <div className="info_wrap">
                          <div className="info2">주의사항</div>
                          <div className="info3">
                            - 입금자명과 출금자명이 다를 경우 본인확인 요청이
                            있을 수 있습니다.
                          </div>
                        </div>
                      </div>
                      <div className="con_box10">
                        <div className="money">
                          <ul>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon.png" height="26" />{" "}
                              보유머니 :{" "}
                              <span className="font05" id="myWalletPop">
                                {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon2.png" height="26" />{" "}
                              콤프 :{" "}
                              <span className="font05" id="myCasPop">
                                {ConverMoeny(this.props.user.rolling)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>

                      <div className="con_box10">
                        <table
                          style={{ width: "100%", border: 0 }}
                          cellSpacing={0}
                          cellPadding={0}
                          className="write_title_top"
                        >
                          <tbody>
                            <tr>
                              <td className="write_title">아이디</td>

                              <td className="write_basic">
                                <input
                                  className="input1"
                                  size={30}
                                  value={this.props.user.id}
                                  readOnly
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="write_title">예금주명</td>

                              <td className="write_basic">
                                <input
                                  className="input1 userName"
                                  size={30}
                                  readOnly
                                  value={this.props.user.bankowner}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="write_title">회원은행</td>

                              <td className="write_basic">
                                <input
                                  className="input1 userBankName"
                                  size={30}
                                  readOnly
                                  value={this.props.user.bankname}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="write_title">신청금액</td>

                              <td className="write_basic">
                                <input
                                  className="input1"
                                  size={30}
                                  readOnly
                                  placeholder="0"
                                  value={this.state.balance}
                                  onFocus={() => this.setState({ balance: "" })}
                                  onChange={(e) =>
                                    this.setState({
                                      balance: e.target.value,
                                    })
                                  }
                                />{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 10000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">1만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 50000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">5만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 100000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">10만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 500000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">50만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 1000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">100만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 5000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">500만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({
                                      balance: `${
                                        Number(this.state.balance) + 10000000
                                      }`,
                                    });
                                  }}
                                >
                                  <span className="btn1_2">1000만원</span>
                                </a>{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.setState({ balance: "0" });
                                  }}
                                >
                                  <span className="btn1_1">정정</span>
                                </a>
                                <br />
                                3만원이상 만원단위로 입금신청해주시기 바랍니다.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="con_box20">
                        <div className="btn_wrap_center">
                          <ul>
                            <li style={{ width: "30%" }}>
                              <a
                                href="javascript:;"
                                style={{ width: "30%" }}
                                onClick={this.handleDoWithdraw}
                              >
                                <span
                                  className="btn3_1"
                                  style={{ width: "30%" }}
                                >
                                  출금신청
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="ngdialog-content"
              role="document"
              style={{ minWidth: "700px" }}
            >
              <div
                className="ngdialog-wallet-page ngdialog-main-default-page ng-scope"
                ng-controller="WalletController"
                ng-init="setTab(selectWalletTab)"
              >
                <div className="ngdialog__heading">
                  <h4
                    className="ngdialog__title text-center"
                    style={{ outline: "0px" }}
                  >
                    <img src="/common/images/logo.png" alt="" />
                  </h4>
                </div>
                <div className="ngdialog__content">
                  <ul className="ngdialog-main-nav list-inline clearfix">
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      <span className="ng-scope">입금 신청</span>
                    </li>
                    <li className="active">
                      <span className="ng-scope">출금 신청</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      <span className="ng-scope">금고</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      <span className="ng-scope">콤프</span>
                    </li>
                  </ul>

                  <div
                    ng-if="isSet(2)"
                    className="ngdialog-main-default__content deposit ng-scope"
                    ng-controller="DepositController"
                  >
                    <div className="deposit manual">
                      <div className="alert alert-danger">
                        <strong className="ng-scope">출금주의사항</strong>
                        <p>
                          1. 입금자명과 출금자명이 다를 경우 본인확인 요청이
                          있을 수 있습니다.
                        </p>
                      </div>
                      <div className="balance-overflow">
                        <div
                          style={{ marginRight: "5px" }}
                          className="ng-scope"
                        >
                          <div className="balance__content">
                            <span>
                              <strong className="text-uppercase text-primary ng-scope">
                                보유머니
                              </strong>
                            </span>
                            <strong className="text-uppercase text-primary pull-right ng-binding">
                              {ConverMoeny(this.props.user.balance)} 원
                            </strong>
                          </div>
                          <div className="balance__content">
                            <span>
                              <strong className="text-uppercase text-primary ng-scope">
                                금고머니
                              </strong>
                            </span>
                            <strong className="text-uppercase text-primary pull-right ng-binding">
                              {ConverMoeny(this.props.user.point)} 원
                            </strong>
                          </div>
                        </div>
                      </div>
                      <form className="ng-pristine ng-valid-maxlength ng-valid ng-valid-required">
                        <fieldset>
                          <label>
                            <span className="ng-scope">아이디</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">예금주명</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>
                            <span className="ng-scope">회원은행</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control ng-pristine ng-untouched ng-valid"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </fieldset>
                        <fieldset style={{ position: "relative" }}>
                          <label>
                            <span className="ng-scope">신청금액</span>{" "}
                            <span>*</span>{" "}
                          </label>
                          <div className="col-xs-5">
                            <input
                              type="text"
                              className="form-control text-right wonText placeholder-center ng-pristine ng-untouched ng-valid ng-valid-maxlength"
                              style={{ marginBottom: "10px" }}
                              readOnly
                              placeholder="0"
                              value={this.state.balance}
                              onFocus={() => this.setState({ balance: "" })}
                              onChange={(e) =>
                                this.setState({
                                  balance: e.target.value,
                                })
                              }
                            />
                            <p className="won-text bonus">원</p>
                            <div
                              style={{ display: "flex" }}
                              ng-include="'includes/wallet-button.php'"
                              className="ng-scope"
                            >
                              <button
                                type="button"
                                className="btn btn-red btn-sm ng-scope"
                                onClick={() => {
                                  this.setState({
                                    balance: `${
                                      Number(this.state.balance) + 10000
                                    }`,
                                  });
                                }}
                              >
                                1만원
                              </button>
                              <button
                                type="button"
                                className="btn btn-red btn-sm ng-scope"
                                onClick={() => {
                                  this.setState({
                                    balance: `${
                                      Number(this.state.balance) + 50000
                                    }`,
                                  });
                                }}
                              >
                                5만원
                              </button>
                              <button
                                type="button"
                                className="btn btn-red btn-sm ng-scope"
                                onClick={() => {
                                  this.setState({
                                    balance: `${
                                      Number(this.state.balance) + 100000
                                    }`,
                                  });
                                }}
                              >
                                10만원
                              </button>
                              <button
                                type="button"
                                className="btn btn-red btn-sm ng-scope"
                                onClick={() => {
                                  this.setState({
                                    balance: `${
                                      Number(this.state.balance) + 500000
                                    }`,
                                  });
                                }}
                              >
                                50만원
                              </button>
                              <button
                                type="button"
                                className="btn btn-red btn-sm ng-scope"
                                onClick={() => {
                                  this.setState({
                                    balance: `${
                                      Number(this.state.balance) + 1000000
                                    }`,
                                  });
                                }}
                              >
                                100만원
                              </button>
                              <button
                                type="button"
                                className="btn btn-red btn-sm btn-clear ng-scope"
                                onClick={() => {
                                  this.setState({ balance: "0" });
                                }}
                              >
                                정정
                              </button>
                            </div>
                          </div>
                          <div className="col-xs-5 no-padding">
                            <span className="side-note ng-scope">
                              최소 출금 금액은 3만원부터 신청 가능합니다.
                            </span>
                          </div>
                        </fieldset>

                        <div className="text-center">
                          <button
                            className="btn btn-primary btn-block ng-scope"
                            onClick={this.handleDoWithdraw}
                          >
                            출금신청
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ngdialog-close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
