import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

export enum noticeView {
  none = "none",
  list = "list",
  view = "view",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2 className="list_notice1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => setOpen(!open)}
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="list_notice2"
        ></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
  mode: string;
  title: string;
  contents: string;
  date: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      notices: [],
      mode: noticeView.list,
      title: "",
      contents: "",
      date: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({
          notices: data.notices,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  nextNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  render() {
    let notices: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.notices.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        notices.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noticeView.list) {
        return <></>;
      }
      return (
        <>
          <div className="con_box10">
            <table
              style={{ width: "100%", border: 0 }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td style={{ width: "10%" }} className="list_title1">
                    번호
                  </td>
                  <td className="list_title1">제목</td>
                  <td style={{ width: "10%" }} className="list_title1">
                    작성일
                  </td>
                </tr>

                {notices.map((info: any, index: any) => (
                  <>
                    <tr
                      onClick={() => {
                        this.setState({
                          title: info.title,
                          contents: info.contents,
                          date: info.regdate,
                          index: index,
                          no: info.no,
                          mode: noticeView.view,
                        });
                      }}
                    >
                      <td className="list_notice1">{info.no}</td>
                      <td className="list_notice2">
                        <a href="javascript:;">{info.title}</a>
                      </td>

                      <td className="list_notice1">
                        {ConvertDate(info.regdate)}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="page_wrap">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: 1,
                      });
                    }}
                  >
                    <span className="page">&lt;&lt;</span>
                  </a>
                  &nbsp;
                </li>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: prevPage,
                      });
                    }}
                  >
                    <span className="page">&lt;</span>
                  </a>
                  &nbsp;
                </li>

                {pages.map((page: any) => {
                  if (page.no === this.state.page) {
                    return (
                      <>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({
                                page: page.no,
                              });
                            }}
                          >
                            <span className="pageon">{page.no}</span>
                          </a>
                        </li>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({
                                page: page.no,
                              });
                            }}
                          >
                            <span className="page">{page.no}</span>
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                <li>
                  &nbsp;
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: nextPage,
                      });
                    }}
                  >
                    <span className="page">&gt;</span>
                  </a>
                </li>
                <li>
                  &nbsp;
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        page: totPage,
                      });
                    }}
                  >
                    <span className="page">&gt;&gt;</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noticeView.view) {
        return <></>;
      }
      return (
        <>
          <div className="con_box10">
            <table
              style={{ width: "98.5%", border: "0", marginLeft: "10px" }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td style={{ height: "30px", textAlign: "right" }}>
                    <span className="view_box">글쓴이</span> 운영자
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="view_box">작성일</span>{" "}
                    {ConvertDate(this.state.date)}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="view1 write_title_top">{this.state.title}</td>
                </tr>
                <tr>
                  <td className="view2">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="btn_wrap_center">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        mode: noticeView.list,
                      });
                    }}
                  >
                    <span className="btn2_1">목록</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw);
                          }}
                        >
                          <span>출금신청</span>
                        </a>
                      </li>
                      {/* <li className="tab3 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.point);
                          }}
                        >
                          <span>금고</span>
                        </a>
                      </li> */}
                      <li className="tab4 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.user);
                          }}
                        >
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.help);
                          }}
                        >
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.note);
                          }}
                        >
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">공지사항 </div>

                      <div className="contents_in">
                        {RenderList()}
                        {RenderView()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="ngdialog-content"
              role="document"
              style={{ minWidth: "700px" }}
            >
              <div
                className="ngdialog-wallet-page ngdialog-main-default-page ng-scope"
                ng-controller="WalletController"
                ng-init="setTab(selectWalletTab)"
              >
                <div className="ngdialog__heading">
                  <h4
                    className="ngdialog__title text-center"
                    style={{ outline: "0px" }}
                  >
                    <img src="/common/images/logo.png" alt="" />
                  </h4>
                </div>
                <div className="ngdialog__content">
                  <ul className="ngdialog-main-nav list-inline clearfix">
                    <li className="active">
                      <span className="ng-scope">공지사항</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      <span className="ng-scope">고객센터</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.user);
                      }}
                    >
                      <span className="ng-scope">마이페이지</span>
                    </li>
                    <li
                      onClick={() => {
                        this.props.handleChange(popupView.note);
                      }}
                    >
                      <span className="ng-scope">쪽지</span>
                    </li>
                  </ul>

                  <div className="ngdialog-main-default__content deposit ng-scope">
                    <div className="ngdialog-main-default__content">
                      {RenderList()}
                      {RenderView()}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ngdialog-close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
