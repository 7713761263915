import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";
import { ConvertDate } from "../utility/help";
import Marquee from "react-fast-marquee";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  SetCookie: (name: string, data: any) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
  notices: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();
  // state: { balance: number; ID: string; PW: string; postCount: number; note: never[]; notes: never[]; popupStatuses: popupView; noteCount: number; expanded: string; mode: gameView; slots: never[]; slotgames: never[]; games: never[]; casionos: never[]; evolgame: null; isOpen: boolean; gameCode: string; gameName: string; notices: never[]; };
  props: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
      notices: [],
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(30000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        // for (const casiono of Object.values(s.casiono) as any) {
        //   if (casiono.nameEn !== "Evolution") {
        //     item.push(casiono);
        //   }
        // }

        this.setState((state1: any) => ({...state1,  casionos: s.casiono,}));
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        }
      } else if (this.props?.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }
  
      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="login_wrap">
              <div className="login">
                <ul>
                  <li>
                    <input
                      name="login_id"
                      id="login_id"
                      type="text"
                      className="input_login"
                      placeholder="아이디"
                      onChange={({ target: { value } }) =>
                        this.setState({ ID: value })
                      }
                    />
                  </li>
                  <li>
                    <input
                      name="login_pw"
                      id="login_pw"
                      type="password"
                      className="input_login"
                      placeholder="비밀번호"
                      onChange={({ target: { value } }) =>
                        this.setState({ PW: value })
                      }
                    />
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        if (
                          this.state.ID == null ||
                          this.state.ID == "" ||
                          this.state.PW == "" ||
                          this.state.PW == null
                        ) {
                          return;
                        }
                        this.props.tryLogin(this.state.ID, this.state.PW);
                      }}
                    >
                      <img src="images/login_btn1.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="casino_1_open"
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.reg });
                      }}
                    >
                      <img src="images/login_btn2.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="login_wrap">
            <span id="UserInfo" style={{ display: "none" }}></span>

            <div className="login">
              <ul>
                <li>
                  <a
                    className="sub_pop2_open"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.note }); //쪽지
                    }}
                  >
                    <img
                      id="letterimg"
                      src="images/letter_img.gif"
                      style={{ width: "16px", height: "16px", border: 0 }}
                    />
                    <span id="letteralarm" style={{ fontSize: "11px" }}></span>
                  </a>
                  <span  style={{ fontSize: "18px", margin : '12px' }}>         &nbsp;{ConverMoeny(this.props.user.balance + this.props.user.in_balance)} 원</span>
         
                </li>

                <li>
                  <img src="images/70AQ7_1.jpg" width="20" />
                  &nbsp;{this.props.user.id}님 환영합니다.
                </li>
                <li>
                  <a
                    className="sub_pop2_open"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.user }); //마이페이지
                    }}
                  >
                    <img src="images/login_btn4.png" />
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      this.props.tryLoginOut();
                    }}
                  >
                    <img src="images/login_btn5.png" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </>
      );
    };

    const RenderLogin2 = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="before-login h-100 active">
              <div className="desktop h-100">
                <button
                  className="login-btn"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.login });
                  }}
                >
                  <i className="fa fa-sign-in-alt"></i> 로그인
                </button>
                <button
                  className="join-btn"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.reg });
                  }}
                >
                  <i className="fa fa-user-circle"></i> 회원가입
                </button>
              </div>
              <div className="mobile">
                <button
                  data-toggle="modal"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.login });
                  }}
                >
                  <i className="fa fa-edit"></i> 로그인
                </button>
                <button
                  data-toggle="modal"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.reg });
                  }}
                >
                  <i className="fa fa-user-plus"></i> 회원가입
                </button>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="after-login active">
            <div className="mobile">
              <button className="right-menu-btn">
                <i className="fa fa-cogs"></i> 내정보보기
              </button>
              <button
                className="logout-btn"
                onClick={() => {
                  this.props.tryLoginOut();
                }}
              >
                <i className="fa fa-sign-out-alt"></i> 로그아웃
              </button>
            </div>
          </div>
        </>
      );
    };

    const RenderLoginMobile = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="before-login h-100 active">
              <div className="desktop h-100"></div>
              <div className="mobile">
                <button
                  data-toggle="modal"
                  data-target=".loginModal"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.login });
                  }}
                >
                  <i className="fa fa-edit"></i> 로그인
                </button>
                <button
                  data-toggle="modal"
                  data-target=".joinModal"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.reg });
                  }}
                >
                  <i className="fa fa-user-plus"></i> 회원가입
                </button>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="after-login  h-100 active">
            <div className="mobile">
              <button className="right-menu-btn">
                <i className="fa fa-cogs"></i> 내정보보기
              </button>
              <button
                className="logout-btn"
                onClick={() => {
                  this.props.tryLoginOut();
                }}
              >
                <i className="fa fa-sign-out-alt"></i> 로그아웃
              </button>
            </div>
          </div>
        </>
      );
    };

    const RenderNotice = () => {
      return (
        <>
          {this.state.notices.map((info: any, index: any) => (
            <span style={{ cursor: "pointer", marginRight: "150px" }} key={`notices_${index}`}>
              <img src="images/notice_title.png" /> ※ {info.title}※
            </span>
          ))}
        </>
      );
    };

    return (
      <>
        <div id="header_wrap">
          <div className="header_box">
            <div className="gnb_wrap">
              <div className="gnb">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.deposit }); //입금신청
                      }}
                    >
                      {/* <img
                        src="images/gnb1.png"
                        style={{ display: "inline" }}
                      />
                      <img
                        src="images/gnb1.png"
                        style={{ display: "none" }}
                        className="mouseover3"
                      /> */}
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        입금신청
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        출금신청
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.comp }); //
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        콤프
                      </span>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.comp }); //
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        콤프
                      </span>
                    </a>
                  </li> */}
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.notice }); //
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        공지사항
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.user }); //
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        마이페이지
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.help }); //
                      }}
                    >
                      <span
                        style={{
                          fontSize: "23px",
                          color: "black",
                          fontWeight: 700,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                        className={this.props.activeHelp ? 'neon' : ''}
                      >
                        고객센터
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="logo">
              <a onClick={()=> window.location.reload()}>
                <img src="images/logo.png" />
              </a>
            </div>
            {RenderLogin()}
          </div>
        </div>

        <div
          style={{
            position: "relative",
            height: "916px",
            overflow: "hidden",
            clear: "both",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "1920px",
              left: "50%",
              marginLeft: "-960px",
            }}
          >
            <div id="slider">
              <img src="images/slideshow1.jpg" />
              <img src="images/slideshow2.jpg" />
            </div>
          </div>
        </div>

        <div className="notice_wrap">
          <div className="notice_box">
            <div className="notice">
              <Marquee
                direction="left"
                delay={0}
                gradientColor={[0, 0, 0]}
                speed={80}
              >
                {RenderNotice()}
              </Marquee>
            </div>
          </div>
        </div>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
